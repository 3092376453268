<template>
    <div class="grid">
        <div class="col-12">
            <div class="card max-h-full">
                <form id="profile" @submit.prevent.stop="profile" class="w-full xl:w-4">
                    <div v-if="response.message && response.color == 'red'" class="alert alert-danger" role="alert">
                        {{ response.message }}
                    </div>

                    <div v-else-if="response.message && response.color == 'green'" class="alert alert-success"
                        role="alert">
                        {{ response.message }}
                    </div>

                    <div class="mb-3">
                        <label for="firstName" class="block text-900 text-xl font-medium mb-1">First Name</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="firstName" name="first-name" type="text" placeholder="First Name"
                                class="w-full md:h-3rem" required v-model="firstName" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="lastName" class="block text-900 text-xl font-medium mb-1">Last Name</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="lastName" name="last-name" type="text" placeholder="Last Name"
                                class="w-full md:h-3rem" required="false" v-model="lastName" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="email" class="block text-900 text-xl font-medium mb-1">Email</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="email" type="text" placeholder="Email address" class="w-full md:h-3rem"
                                required v-model="email" />
                        </InputGroup>
                        <!-- <div class="error-msg form-floating">
                <span class="error-msg" v-if="!checkEmail()">{{ messages.EmailInvalid }}</span>
            </div> -->
                    </div>

                    <div class="mb-3">
                        <label for="password" class="block text-900 text-xl font-medium mb-1">Password</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-key"></i>
                            </InputGroupAddon>
                            <Password v-model="password" placeholder="Password" :toggleMask="true"
                                class="w-full md:h-3rem" :feedback="true" required>
                                <template #header>
                                    <div class="font-semibold text-xm mb-4">Pick a password</div>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <ul class="pl-2 ml-2 my-0 leading-normal">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>At least one non numeric character</li>
                                        <li>Minimum 8 characters</li>
                                    </ul>
                                </template>
                            </Password>
                        </InputGroup>
                    </div>

                    <div class="mb-5">
                        <label for="repassword" class="block text-900 text-xl font-medium mb-1">Retype</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-key"></i>
                            </InputGroupAddon>
                            <Password v-model="repassword" placeholder="Retype Password" :toggleMask="true"
                                @click="reCheckPassword" class="w-full md:h-3rem" :feedback="true" required>
                                <template #header>
                                    <div class="font-semibold text-xm mb-4">Pick a password</div>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <ul class="pl-2 ml-2 my-0 leading-normal">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>At least one non numeric character</li>
                                        <li>Minimum 8 characters</li>
                                    </ul>
                                </template>
                            </Password>
                        </InputGroup>
                    </div>

                    <Button type="submit" style="border-radius: 5px;" :label="spinner.message"
                        class="w-full p-3 md:h-3rem mb-3"
                        :disabled="spinner.resetPassword || !checkEmail() || !checkPassword()" id="btn-resetPassword">
                        <img v-if="spinner.resetPassword" width="30" src="../../assets/img/spinner_color_white.svg"
                            alt="" class="">
                    </Button>

                    <div>
                        <div v-if="!checkEmail()" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.EmailInvalid }}
                        </div>
                        <div v-if="!checkPassword()" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.PasswordInvalidFormat }}
                        </div>
                        <div v-if="!reCheckPassword" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.PasswordsNotMatch }}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>

import axios from 'axios';
import message from '../../plugins/messages';
const util = require('../../plugins/util');
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'ProfileForm',
    components: {},

    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            repassword: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                profile: false,
                message: message.UpdateProfileFalse,
            },
            showPassword: false,
            messages: message,
        }
    },

    computed: {
        ...mapState({
            user: state => state.user.user,
        }),

        reCheckPassword() {
            // console.log(this.password === this.repassword);
            return this.password === this.repassword;
        },

    },

    created() {
        this.firstName = this.user.first_name;
        this.lastName = this.user.last_name;
        this.email = this.user.email;
    },

    methods: {
        ...mapMutations('user', ['STORE_USER', 'STORE_ROLES']),

        profile() {

            const payload = {
                first_name: this.firstName,
                last_name: this.lastName || "",
                email: this.email,
            };

            if (this.password) {
                payload.password = this.password;
            }

            // console.log(payload);
            this.resetResponse();

            axios.put('me', payload)
                .then(response => {

                    // this.clearForm();
                    // console.log(response.data);

                    this.response.color = 'green';
                    this.response.message = message.UpdateProfileSuccess;
                    this.spinner.message = message.UpdateProfileFalse;

                    this.STORE_USER(response.data.data);
                    this.STORE_ROLES(response.data.roles);

                })
                .catch((e) => {
                    console.log(e);
                    const errorCode = e?.response?.data?.error || 'ServerError';
                    this.response.color = 'red';
                    this.response.message = message[errorCode];

                    this.spinner.message = message.UpdateProfileFalse;

                }).finally(() => {
                    this.spinner.profile = false;
                })

        },

        checkEmail() {
            return util.validaEmail(this.email);
        },

        checkPassword() {
            return ((util.validaPassword(this.password) && util.validaPassword(this.repassword)) && (this.password === this.repassword));
        },

        resetResponse() {
            this.response.color = '';
            this.response.message = '';

            this.spinner.profile = true;
            this.spinner.message = message.UpdateProfileTrue;
        },

        clearForm() {
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.password = '';
        },

    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span {
    margin: 40px 0 0;
    text-align: left;
}

.error-msg {
    margin-top: 5px;
    text-align: left;
    color: red;
    font-weight: bold;
    font-size: 15px;
}

.error-msg-small {
    font-size: 11px;
}

span.error-msg {
    margin-left: 10px;
}
</style>