<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-2 align-middle">
                    <template #start>
                        <Button label="New" icon="pi pi-plus" outlined class="mr-2" @click="addTodo"
                            style="border-radius: 5px;" />
                        <Button label="Export" icon="pi pi-upload" outlined @click="exportCSV($event)"
                            style="border-radius: 5px;" />
                    </template>
                    <template #center>
                        <h5 class="align-middle my-1">Filter Menu</h5>
                    </template>
                    <template #end>
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="mr-2" outlined
                            @click="clearFilter()" style="border-radius: 5px;" />
                        <IconField iconPosition="left">
                            <InputIcon class="pi pi-search" />
                            <InputText v-model="filters1['global'].value" placeholder="Keyword Search"
                                style="width: 12rem" />
                        </IconField>
                    </template>
                </Toolbar>
                <DataTable class="border-4" :value="data" :paginator="true" :rows="10"
                    :rowsPerPageOptions="[10, 20, 50]" sortField="id" :sortOrder="-1" :rowHover="true" showGridlines
                    filterDisplay="menu" :loading="loading2" stripedRows removableSort
                    :globalFilterFields="`${globalFilters}`" tableStyle="min-width: 50rem" :filters="filters1"
                    v-model:filters="filters1">

                    <template #header>
                    </template>

                    <template #empty> No customers found. </template>
                    <template #loading> Loading customers data. Please wait. </template>
                    <Column v-for="(field, idx) in fields" :key="idx" :field="`${field.key}`" :header="`${field.label}`"
                        style="min-width: 12rem" :dataType="`${field.dataType}`" sortable :filter-field="`${field.key}`"
                        :sort-field="`${field.key}`">
                        <template #body="{ data }">
                            <div v-if="field.type == 'date'">
                                {{ formatDate(data[field.key]) }}
                            </div>
                            <div v-else>
                                {{ data[field.key] }}
                            </div>
                        </template>
                        <template v-if="field.dataType !== 'date'" #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                :placeholder="`Search by ${field.label}`" />
                        </template>
                        <template v-else #filter="{ filterModel }">
                            <Calendar type="text" v-model="filterModel.value" dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </template>
                    </Column>

                    <!-- <Column :exportable="false" style="min-width: 12rem">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="editTodo(data)" />
                            <Button icon="pi pi-trash" outlined rounded severity="danger"
                                @click="confirmDeleteTodo(data)" />
                        </template>
                    </Column> -->

                    <Column :exportable="false" style="min-width: 12rem">
                        <template #body="{ data }">

                            <!-- <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="editItem(data)" />
                            <Button icon="pi pi-trash" outlined rounded severity="danger"
                                @click="confirmDeleteItem(data)" /> -->

                            <div :class="`dropdown`">
                                <a href="#" data-bs-toggle="dropdown" aria-expanded="true">
                                    <i class="pi pi-ellipsis-v" :class="`text-${themeMenu}`"></i>
                                </a>
                                <ul class="dropdown-menu" :style="`background-color: ${bkgMenu};`">
                                    <li><Button @click="editTodo(data)"
                                            :style="`color: ${fontMenu}; background-color: ${bkgMenu}; border-color: ${bkgMenu};`"
                                            href="#">Edit</Button>
                                    </li>
                                    <li><Button @click="confirmDeleteTodo(data)"
                                            :style="`color: ${fontMenu}; background-color: ${bkgMenu}; border-color: ${bkgMenu};`"
                                            href="#">Delete</Button>
                                    </li>
                                </ul>
                            </div>

                        </template>
                    </Column>

                    <template #footer>
                        <!-- Total de registros: {{  }} -->
                    </template>
                </DataTable>
                <Dialog v-model:visible="todoDialog" :style="{ width: '450px' }" header="Todo Details" :modal="true"
                    class="p-fluid">
                    <div class="field">
                        <label>Label</label>
                        <InputText v-model="todo.label" required="true" autofocus :invalid="submitted && !todo.label" />
                        <small class="p-invalid" v-if="submitted && !todo.label">Label is required.</small>
                    </div>
                    <useToast></useToast>
                    <template #footer>
                        <Button label="Cancel" icon="pi pi-times" text="" @click="hideDialog" />
                        <Button label="Save" icon="pi pi-check" severity="success" text="" @click="saveTodo" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteTodoDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="todo">Are you sure you want to delete the selected todo?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" text @click="deleteTodoDialog = false" />
                        <Button label="Yes" icon="pi pi-check" text @click="deleteTodo()" />
                    </template>
                </Dialog>

            </div>
        </div>
    </div>
</template>

<script>

import { FilterMatchMode, FilterOperator } from 'primevue/api';
import Calendar from 'primevue/calendar';
import { useLayout } from '@/layout/composables/layout';

const layoutConfig = useLayout();

export default {

    component: {
        Calendar,
    },

    data() {
        return {
            filters1: [],
            loading2: false,
            globalFilters: [],
            todoDialog: false,
            deleteTodoDialog: false,
            submitted: false,
            todo: {},
            dataLocal: [],
        }
    },
    created() { },
    beforeMount() {
        this.initFilters1();
        this.setGlobalFilters();
    },

    mounted() {

    },
    props: {
        data: {
            type: Array,
            required: true,
            default: () => []
        },
        header: {
            type: Object,
            required: false,
            default: () => null
        },
        fields: {
            type: Array,
            required: true,
            default: () => []
        },
        rowSelector: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {

        themeMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'light' : 'dark';
        },

        fontMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'white' : 'black';
        },

        bkgMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'black' : 'white';
        },
    },

    methods: {
        rowSelected(item) {
            item.selected = !item.selected
            if (item.selected) {
                this.$emit('rowSelected', item)
            }
            else {
                this.$emit('rowSelected', null)
            }
        },
        selectAll(e) {
            const checked = e.target.checked
            this.data.forEach((item) => { item.selected = checked })
            this.$forceUpdate()
        },

        selectColumn(item) {
            this.$emit('selectColumn', item.key, item.type)
        },


        formatDate(value) {
            let date = new Date(value);
            return date.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        },

        initFilters1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                user_id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                label: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                updated_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            };
        },

        setGlobalFilters() {
            this.dataLocal.forEach(element => {
                this.globalFilters.push(element.key);
            });

        },

        clearFilter() {
            this.initFilters1();
        },

        hideDialog() {
            this.todoDialog = false;
            this.submitted = false;
        },

        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.dataLocal.length; i++) {
                if (this.dataLocal.id[i] === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        saveTodo() {
            this.submitted = true;
            if (this.todo.id) {
                this.dataLocal[this.findIndexById(this.todo.id)] = this.todo;
                this.$emit('afterUpdating', this.todo);
            } else {
                this.$emit('afterUAdditing', this.todo);
            }
            this.todoDialog = false;
        },

        deleteTodo() {
            this.submitted = true;
            this.deleteTodoDialog = false;
            this.$emit('afterDeleting', this.todo);
        },
        editTodo(data) {
            this.todo = { ...data };
            this.todoDialog = true;
        },
        confirmDeleteTodo(data) {
            this.todo = { ...data };
            this.deleteTodoDialog = true;
        },
        addTodo() {
            this.todo = {};
            this.todoDialog = true;
        },
    }
}
</script>