<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <form v-if="!error.error" id="register" @submit.prevent.stop="register" class="w-full xl:w-4">
                    <div v-if="response.message && response.color == 'red'" class="alert alert-danger" role="alert">
                        {{ response.message }}
                    </div>

                    <div v-else-if="response.message && response.color == 'green'" class="alert alert-success"
                        role="alert">
                        {{ response.message }}
                    </div>

                    <!-- <div class="mb-3">
                        <label for="project" class="block text-900 text-xl font-medium mb-1">Projects</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-globe"></i>
                            </InputGroupAddon>
                            <Dropdown class="w-full md:h-3rem align-center" v-model="dropdownValue"
                                :options="dropdownValues" optionLabel="name" placeholder="Project" required />
                        </InputGroup>
                    </div> -->

                    <div class="mb-3">
                        <label for="firstName" class="block text-900 text-xl font-medium mb-1">First Name</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="firstName" name="first-name" type="text" placeholder="First Name"
                                class="w-full md:h-3rem" required v-model="firstName" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="lastName" class="block text-900 text-xl font-medium mb-1">Last Name</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="lastName" name="last-name" type="text" placeholder="Last Name"
                                class="w-full md:h-3rem" required="false" v-model="lastName" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="email" class="block text-900 text-xl font-medium mb-1">Email</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="email" type="text" placeholder="Email address" class="w-full md:h-3rem"
                                required v-model="email" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="password" class="block text-900 text-xl font-medium mb-1">Password</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-key"></i>
                            </InputGroupAddon>
                            <Password v-model="password" placeholder="Password" :toggleMask="true"
                                class="w-full md:w-30rem md:h-3rem" :feedback="true" required>
                                <template #header>
                                    <div class="font-semibold text-xm mb-4">Pick a password</div>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <ul class="pl-2 ml-2 my-0 leading-normal">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>At least one non numeric character</li>
                                        <li>Minimum 8 characters</li>
                                    </ul>
                                </template>
                            </Password>
                        </InputGroup>
                    </div>

                    <div class="mb-5">
                        <label for="repassword" class="block text-900 text-xl font-medium mb-1">Retype</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-key"></i>
                            </InputGroupAddon>
                            <Password v-model="repassword" placeholder="Retype Password" :toggleMask="true"
                                @click="reCheckPassword" class="w-full md:w-30rem md:h-3rem" :feedback="true" required>
                                <template #header>
                                    <div class="font-semibold text-xm mb-4">Pick a password</div>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <ul class="pl-2 ml-2 my-0 leading-normal">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>At least one non numeric character</li>
                                        <li>Minimum 8 characters</li>
                                    </ul>
                                </template>
                            </Password>
                        </InputGroup>
                    </div>

                    <Button type="submit" style="border-radius: 5px;" :label="spinner.message"
                        class="w-full p-3 md:h-3rem mb-3 sm:h-1em" outlined
                        :disabled="spinner.register || !checkEmail() || !checkPassword()" id="btn-register">
                        <img v-if="spinner.register" width="30" src="../../assets/img/spinner_color_white.svg" alt=""
                            class="">
                    </Button>

                    <div>
                        <!-- <div v-if="!checkProject() && firstName" class="align-middle alert alert-danger w-full" role="alert"> -->
                        <!-- <div v-if="!checkProject() && firstName" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.ProjectInvalid }}
                        </div> -->
                        <div v-if="!checkEmail()" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.EmailInvalid }}
                        </div>
                        <div v-if="!checkPassword()" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.PasswordInvalidFormat }}
                        </div>
                        <div v-if="!reCheckPassword" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.PasswordsNotMatch }}
                        </div>
                    </div>
                </form>
                <FormBodyError v-if="error.error" :errorNumber="error.errorNumber" :errorMsg="error.errorMsg">
                </FormBodyError>
            </div>
        </div>
    </div>

</template>

<script>

import axios from 'axios';
import message from '../../plugins/messages';
const util = require('../../plugins/util');

import FormBodyError from './FormBodyError.vue';

export default {
    name: 'RegisterForm',
    components: { FormBodyError },

    props: {},

    data() {
        return {
            error: {
                error: false,
                errorNumber: null,
                errorMsg: null,
            },
            project_id: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            repassword: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                register: false,
                message: message.RegisterFalse,
            },
            messages: message,
            projects: [],
            dropdownValue: {
                code: 0,
                name: ''
            },
            // dropdownValues: [],
        }
    },

    created() {
        // this.getProjects();
    },

    computed: {
        reCheckPassword() {
            return this.password === this.repassword;
        },
    },

    methods: {
        // getProjects() {

        //     this.spinner.show = true;

        //     axios.get('projects')
        //         .then(response => {

        //             let projects = response.data.data;

        //             projects.forEach(element => {
        //                 this.dropdownValues.push({ code: element.id, name: element.name });
        //             });
        //         })
        //         .catch((e) => {
        //             console.log(e);
        //             this.error.errorNumber = e.response.status;
        //             this.error.errorMsg = e.response.data.message;
        //             this.error.error = true;

        //         }).finally(() => {
        //             this.spinner.show = false;
        //         });
        // },

        register() {

            const payload = {
                project_id: this.dropdownValue.code,
                first_name: this.firstName,
                last_name: this.lastName || "",
                email: this.email,
                password: this.password,
            };
            // console.log(payload);
            this.resetResponse();

            axios.post('register', payload)
                .then(response => {

                    this.clearForm()
                    console.log(response.data);

                    this.response.color = 'green';
                    this.response.message = message.RegisterSuccess;
                    this.spinner.message = message.RegisterFalse;

                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Usuário cadastrado com sucesso.', life: 3000 });

                })
                .catch((e) => {
                    console.log(e);
                    const errorCode = e?.response?.data?.error || 'ServerError';
                    this.response.color = 'red';
                    this.response.message = message[errorCode];

                    this.spinner.message = message.RegisterFalse;
                    
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.error, life: 5000 });

                }).finally(() => {
                    this.spinner.register = false;
                })

        },

        // checkProject() {
        //     return ((this.dropdownValue.code > 0));
        // },

        checkEmail() {
            return util.validaEmail(this.email);
        },

        checkPassword() {
            return ((util.validaPassword(this.password) && util.validaPassword(this.repassword)) && (this.password === this.repassword)
            );
        },

        resetResponse() {
            this.response.color = '';
            this.response.message = '';

            this.spinner.register = true;
            this.spinner.message = message.RegisterTrue;
        },

        clearForm() {
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.password = '';
            this.repassword = '';
        },

    },
};
</script>
