<template>
  <FormBodyProfile></FormBodyProfile>
</template>

<script>
import FormBodyProfile from '@/components/auth/FormBodyProfile.vue';

export default {
  name: 'ProfilePage',
  components: {
    FormBodyProfile,
  },

  data() {
    return {
    }
  },
};
</script>