<template>
    <FormBodyError :errorNumber="403"></FormBodyError>
</template>

<script>
import FormBodyError from '@/components/auth/FormBodyError.vue'

export default {
    name: 'ErrorPage',
    components: {
        FormBodyError,
    },

    data() {
        return {
        }
    },
};
</script>