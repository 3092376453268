<template>
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

  <h1>Page Not Found (404)</h1>
  
</template>

<script>

export default {
  name: 'NotFoundPage',
  components: {

  },

  data() {
  },


  methods: {
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span {
  margin: 40px 0 0;
  text-align: left;
}

.error-msg {
  margin-top: 5px;
  text-align: left;
  color: red;
  font-weight: bold;
  font-size: 15px;
}

.error-msg-small {
  font-size: 11px;
}

#btn-show-pwd:hover {
  color: #3a33b8;
  text-decoration: none;
}

span.error-msg {
  margin-left: 10px;
}
</style>