<template>
  <TableWithFilter :fields=myFields :data=todos @afterUpdating="afterUpdating" @afterDeleting="afterDeleting"
    @afterUAdditing="afterUAdditing">
  </TableWithFilter>
</template>

<script>

import TableWithFilter from '../../components/table/TableWithFilter.vue'
import axios from 'axios';

export default {
  name: 'TableExamplePage',
  // el: '#sss',
  components: {
    TableWithFilter
  },
  data() {
    return {
      todos: [],
      myFields: [
        { "key": "id", "label": "Id", "type": "int", "dataType": "numeric" },
        { "key": "label", "label": "Tarefa", "type": "str", "dataType": "text" },
        { "key": "user_id", "label": "User ID", "type": "int", "dataType": "numeric" },
        { "key": "created_at", "label": "Criado", "type": "date", "dataType": "date" },
        { "key": "updated_at", "label": "Atualizado", "type": "date", "dataType": "date" },
      ],
    }
  },
  created: function () {
    this.getTodos();
  },
  methods: {
    getTodos() {
      axios.get('todos')
        .then(response => {
          this.todos = response.data.data;

          this.todos.forEach(element => { //Para filtros de data funcionarem
            element.created_at = new Date(element.created_at);
            element.updated_at = new Date(element.updated_at);
          });

        })
        .catch((e) => {
          console.log(e);

        }).finally(() => {
          // this.spinner.show = false
        });
    },

    updateTodo(todo) {

      if (!todo.label) { return; }
      const payload = {
        label: todo.label,
      };

      // console.log(todo.label);
      axios.put(`todos/${todo.id}`, payload)
        .then(() => {
          const idx = this.todos.findIndex(o => o.id === todo.id);
          this.todos[idx] = todo;
          this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Dados atualizados com sucesso.', life: 3000 });
        })
        .catch((e) => {
          console.log(e);
          this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.error, life: 5000 });

        }).finally(() => {
          // this.spinner.show = false;
        });

    },

    deleteTodo(todo) {
      // console.log(todo.id);
      axios.delete(`todos/${todo.id}`)
        .then(() => {
          const idx = this.todos.findIndex(o => o.id === todo.id);
          this.todos.splice(idx, 1);
          this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Dados deletados com sucesso.', life: 3000 });
        })
        .catch((e) => {
          console.log(e);
          this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.error, life: 5000 });


        }).finally(() => {
          // this.spinner.show = false;
        });

    },

    addTodo(newTodo) {


      const payload = {
        label: newTodo.label,
      };

      axios.post('todos', payload)
        .then(response => {

          // this.todos.push(response.data.data);//insere o item no final da lista todos: [],
          this.todos.unshift(response.data.data);//insere o item no começo da lista todos: [],
          this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Dados inseridos com sucesso.', life: 3000 });

        })
        .catch((e) => {
          console.log(e);
          this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.error, life: 5000 });

        }).finally(() => {

        });

    },

    afterUpdating(todoUpdated) {
      this.updateTodo(todoUpdated);
    },

    afterDeleting(todoDeleted) {
      this.deleteTodo(todoDeleted);
    },

    afterUAdditing(todoAdd) {
      this.addTodo(todoAdd);
    },
  },
  computed: {}

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>