<template>
    <TableUsers ></TableUsers>
</template>

<script>

import TableUsers from '../../components/table/TableUsers.vue'

export default {
    name: 'UsersPage',
    components: { TableUsers, },
    data() { return {} },
    created: function () {},
    methods: {},
    computed: {}
};

</script>

<style></style>
