<template>
  <title>{{ this.$route.meta.title }}</title>
  <router-view />
  <!-- {{ this.$route['matched'].at(0).components.default.name }} -->
  <div></div>
</template>

<style scoped></style>

<!-- <script setup>
import { usePrimeVue } from 'primevue/config';
import { useLayout } from '@/layout/composables/layout';
import { onMounted } from 'vue';
import { ThemeService } from '@/service/ThemeService';

const $primevue = usePrimeVue();
const { layoutConfig } = useLayout();
const themeService = new ThemeService();

onMounted(() => {

  themeService.getMePreference().then(response => {

    try {
      let theme = response.data.data.theme;
      let theme_mode = response.data.data.theme_mode;

      if (theme && theme_mode)
        onChangeTheme(theme, theme_mode)
      else
        onChangeTheme("aura-light-lime", "light")

    } catch (error) {
      console.log(error);
    }

  });

  // themeService.getTheme().then((data) => (
  //   onChangeTheme(data[0].theme, data[0].mode)
  // ));

});

const onChangeTheme = (theme, mode) => {
  $primevue.changeTheme(layoutConfig.theme.value, theme, 'theme-css', () => {
    layoutConfig.theme.value = theme;
    layoutConfig.darkTheme.value = mode;
  });
};
</script> -->
