<template>
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

  <h1>Upload File</h1>

  <div class="container">
    <div class="large-12 medium-12 small-12 filezone">
      <input type="file" id="files" ref="files" multiple v-on:change="handleFiles()" />
      <p>
        Drop your files here <br>or click to search
      </p>
    </div>

    <div v-for="(file, key) in files" :key="file.id" class="file-listing">
      <img class="preview" v-bind:ref="'preview' + parseInt(key)" />
      {{ file.name }}
      <div class="success-container" v-if="file.id > 0">
        Success
      </div>
      <div class="remove-container" v-else>
        <a class="remove" v-on:click="removeFile(key)">Remove</a>
      </div>
    </div>

    <a class="submit-button" v-on:click="submitFiles()" v-show="files.length > 0">Submit</a>
  </div>
</template>

<script>

import axios from 'axios';
// import Cookie from 'js-cookie';
// import message from '../plugins/messages';

export default {
  name: 'UploadFilePage',
  components: {

  },

  props: {
    msg: String
  },

  data() {
    return {
      files: []
    }
  },


  created() {

  },

  methods: {
    handleFiles() {
      let uploadedFiles = this.$refs.files.files;

      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
      this.getImagePreviews();
    },

    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
          let reader = new FileReader();
          reader.addEventListener("load", function () {
            this.$refs['preview' + parseInt(i)][0].src = reader.result;
          }.bind(this), false);
          reader.readAsDataURL(this.files[i]);
        } else {
          this.$nextTick(function () {
            this.$refs['preview' + parseInt(i)][0].src = '/img/generic.png';
          });
        }
      }
    },

    removeFile(key) {
      this.files.splice(key, 1);
      this.getImagePreviews();
    },

    submitFiles() {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].id) {
          continue;
        }
        let formData = new FormData();
        formData.append('file', this.files[i]);
// '/' + this.post_url
        axios.post('upload-file',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function (data) {
          this.files[i].id = data['data']['id'];
          this.files.splice(i, 1, this.files[i]);
          console.log('success');
          console.log(data.data);
        }
        .bind(this))
        .catch((e) => {
          console.log(e);
          // const errorCode = e?.response?.data?.error || 'ServerError';
          // this.response.color = 'red';
          // this.response.message = message[errorCode];

        });
      }
    }
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span {
  margin: 40px 0 0;
  text-align: left;
}

.error-msg {
  margin-top: 5px;
  text-align: left;
  color: red;
  font-weight: bold;
  font-size: 15px;
}

.error-msg-small {
  font-size: 11px;
}

#btn-show-pwd:hover {
  color: #3a33b8;
  text-decoration: none;
}

span.error-msg {
  margin-left: 10px;
}
</style>