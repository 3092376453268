<template>
  <FormAuth :formTitle="formTitle" :linkTo="linkTo" :linkTitle="linkTitle" :viewTo="viewTo"></FormAuth>
</template>

<script>

import FormAuth from '@/components/auth/FormAuth.vue';

export default {
  name: 'VerifyEmailPage',
  components: {
    FormAuth,
  },

  data() {
    return {
      formTitle: 'Verify Email',
      linkTo: '/login',
      linkTitle: 'To login?',
      viewTo: 'VerifyEmailPage',
    }
  },
};
</script>
