<template>
    <div class="grid">

        <div class="col-12">
            <div class="card">
                <h5>Image</h5>
                <div class="grid">
                    <div v-for="image in imagePaths" :key="image.path" class="col">
                        <Image :src="imageDefaultUrl.replace('api/v1', '') + image.path" alt="Image" width="250"
                            preview />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
                <h5>Galleria</h5>
                <Galleria :value="imagePaths" :responsiveOptions="galleriaResponsiveOptions" :numVisible="5"
                    :showThumbnails="false" :showIndicators="true" :indicatorsPosition="position"
                    :showItemNavigators="true" :circular="true" containerStyle="width: 250px; margin: auto" preview>
                    <template #item="slotProps">
                        <img :src="imageDefaultUrl.replace('api/v1', '') + slotProps.item.path"
                            :alt="slotProps.item.filename" style=" display: block" width="250" />
                    </template>
                </Galleria>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
                <h5>Carousel</h5>
                <Carousel :value="imagePaths" :numVisible="1" :numScroll="1" :responsiveOptions="responsiveOptions">
                    <template #item="slotProps">
                        <div class="border border-surface-200 dark:border-surface-700 rounded m-2  p-2">
                            <div class="mb-1">
                                <div class="flex align-items-center justify-content-center">
                                    <img :src="imageDefaultUrl.replace('api/v1', '') + slotProps.data.path"
                                        :alt="slotProps.data.name" class="w-full xl:w-4 rounded flex align-items-center justify-content-center" />
                                </div>
                            </div>
                        </div>
                    </template>
                </Carousel>
            </div>
        </div>

    </div>


</template>

<script>

import axios from 'axios';

export default {
    name: 'MediaPage',
    data() { return { imagePaths: [], imageDefaultUrl: axios.defaults.baseURL } },
    created: function () { this.loadImages(); },
    methods: {
        loadImages() {

            axios.get('images')
                .then(response => { this.imagePaths = response.data.data; console.log(this.imagePaths); })
                .catch((e) => { console.log(e); })
                .finally(() => { })
        },
    },
    computed: {}
};

</script>

<style></style>
