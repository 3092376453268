<script setup>
import { ref, onBeforeMount } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

import router from '@/router'
import store from "@/store";
import message from '@/plugins/messages';

const validateMenuItem = () => {
    let routes = router.getRoutes();
    let disabledPathItem = [];
    let countItem = 0;

    //Só realiza a validação caso o usuário não seja 'Super Admin' 
    
    if (!store.state.user.roles.includes(message.master)) {
        routes.forEach(route => {
            if (route.meta.roles && route.meta.roles.length > 0) {
                try {
                    // Verifica quais 'paths' o usuário não pode acessar
                    store.state.user.roles.forEach(role => {
                        if (!route.meta.roles.includes(role)) {
                            disabledPathItem.push(route.meta.path);
                        }
                    });

                } catch (error) { console.log('') }
            }
        });

        // Desabilita os menus (paths) inacessíveis ao usuário
        model.value.forEach(item => {
            countItem = 0;
            item.items.forEach(it => {
                if (disabledPathItem.includes(it.to)) {
                    it.visible = false;
                    countItem++;
                }
                // console.log('Label: ' + item.label);
                // console.log('Total: ' + item.items.length);
                // console.log('Oculto: ' + countItem);
                // console.log('---------------');

                if ((item.items.length - countItem) == 0) {
                    item.visible = false;
                }
            })
        })
    }

    return disabledPathItem;

}

onBeforeMount(() => {
    validateMenuItem();
});

const model = ref([
    {
        label: 'Home',
        items: [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/', visible: true },
        ]
    },
    {
        label: 'Gerenciamento',
        items: [
            { label: 'New User', icon: 'pi pi-fw pi-user', to: '/register', visible: false },
            { label: 'Users', icon: 'pi pi-fw pi-users', to: '/users', visible: true },
            { label: 'Roles', icon: 'pi pi-fw pi-exclamation-circle', to: '/roles', visible: true },
            { label: 'Table Example', icon: 'pi pi-fw pi-table', to: '/table-example', visible: true },
        ]
    },
    {
        label: 'Visitas',
        items: [
            { label: 'Unidades Consumidoras', icon: 'pi pi-fw pi-list', to: '/mv/unidadesconsumidoras', visible: true }
        ]
    },
    {
        label: 'Medição de Tempo',
        items: [
            { label: 'Memória de Massa - HMM', icon: 'pi pi-fw pi-hourglass', to: '/mv/medicaohorahmm', visible: true },
            { label: 'Convencional', icon: 'pi pi-fw pi-hourglass', to: '/mv/medicaohoracon', visible: true }
        ]
    },
    {
        label: 'Medição de Energia',
        items: [
            { label: 'Potência', icon: 'pi pi-fw pi-bars', to: '/mv/medicaopotencia', visible: true },
        ]
    },
    {
        label: 'Medidores',
        items: [
            { label: 'Horímetros', icon: 'pi pi-fw pi-stopwatch', to: '/mv/medidortempo', visible: true },
            { label: 'Energia', icon: 'pi pi-fw pi-bolt', to: '/mv/medidorenergia', visible: true },
        ]
    },
    {
        label: 'Ajustes',
        items: [
            { label: 'Feriados', icon: 'pi pi-fw pi-calendar', to: '/mv/feriados', visible: true },
            { label: 'Horário Ponta Projeto', icon: 'pi pi-fw pi-flag', to: '/mv/horaponta', visible: true },
            { label: 'Total de Lâmpadas', icon: 'pi pi-fw pi-sitemap', to: '/mv/lampadastotal', visible: true },
        ]
    },
    {
        label: 'Aplicativo',
        items: [
            { label: 'Download', icon: 'pi pi-fw pi-download', to: '/mv/downloadapp', visible: true },
        ]
    },
    {
        label: 'Demo',
        items: [
            { label: 'Upload', icon: 'pi pi-fw pi-upload', to: '/demo/upload', visible: true },
            { label: 'Media', icon: 'pi pi-fw pi-images', to: '/demo/media', visible: true },
        ]
    },
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
