import axios from "axios";
import Cookie from "@/service/cookie";

// axios.defaults.baseURL = 'http://www.api-todolist.com/api/v1';
// axios.defaults.baseURL = 'https://localhost:44345/api/Login'; // Login na Api do MV App
// axios.defaults.baseURL = 'http://www.api-todolist.com/api/v1';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1';
axios.defaults.baseURL = 'https://api.jhse.com.br/api/v1';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use(async config =>  {
    const token = Cookie.getToken();

    if (token) {
        axios.defaults.headers.common['Authorization'] = token;
        config.headers.authorization =token;
    }

    return config;
})