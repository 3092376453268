<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
// import { useRouter } from 'vue-router';
import logout from '@/service/logout';
import { useRouter } from 'vue-router';
import { useConfirm } from "primevue/useconfirm";
import { useStore } from 'vuex';

const confirm = useConfirm();
const store = useStore();

const router = useRouter();

const { layoutConfig, onMenuToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

onMounted(() => {
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const logoUrl = computed(() => {
    if ((layoutConfig.darkTheme.value === true) || (layoutConfig.darkTheme.value === 'dark'))
        return `/layout/images/logo-white.png`;

    else
        return `/layout/images/logo-dark.png`;

});

const onTopBarMenuButton = () => {
    topbarMenuActive.value = !topbarMenuActive.value;
};
// const onSettingsClick = () => {
//     topbarMenuActive.value = false;
//     logout.logout();
//     router.push({ name: 'login' });
// };

const onProfileClick = () => {
    topbarMenuActive.value = false;
    router.push({ name: 'profile' });
};

const isVisible = ref(false);
const onLogoutMenuClick = (event) => {
    confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        onShow: () => {
            isVisible.value = true;
        },
        onHide: () => {
            isVisible.value = false;
        },

        accept: () => {
            topbarMenuActive.value = false;
            logout.logout();
            router.push({ name: 'login' });
        }
    });
}

const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>

    <div class="layout-topbar">
        <router-link to="/" class="layout-topbar-logo">
            <img v-show="true" :src="logoUrl" alt="logo" />
        </router-link>

        <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>

        <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <!-- <div class="text-sm" v-show="true"> {{ `${store.state.user.user.first_name} < ${store.state.user.user.email}>` }}</div> -->

        <div class="layout-topbar-menu" :class="topbarMenuClasses">

            <button class="text-sm p-link layout-topbar-button mx-5">
                {{ `${store.state.user.user.first_name}` }}
                {{ `<${store.state.user.user.email}>` }}
            </button>

            <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
                <i class="pi pi-calendar"></i>
                <span>Calendar</span>
            </button>

            <ConfirmPopup id="confirm" aria-label="popup" />

            <button @click="onProfileClick()" class="p-link layout-topbar-button">
                <!-- <button @click="openPopup($event)" class="p-link layout-topbar-button"> -->
                <i class="pi pi-user"></i>
                <span>Profile</span>
            </button>
            <button @click="onLogoutMenuClick($event)" class="p-link layout-topbar-button">
                <i class="pi pi-sign-out"></i>
                <span>Logout</span>
            </button>
        </div>
    </div>
</template>
