export default {
    ServerError: 'Ops! Alguma coisa deu errado.',
    LoginInvalidException: 'Ops! E-mail e/ou senhas inválidos.',
    
    UserHasBeenTakenException: 'Já existe um usuário cadastrado com esse e-mail.',
    VerifyEmailTokenInvalidException: 'Ops! Parece que esse token é inválido.',
    VerifyEmailTokenInvalidSuccess: 'E-mail verificado com sucesso',
    EmailNotVerifiedException: 'É necessário verificar o e-mail antes do primeiro acesso.',
    EmailVerified: 'É necessário verificar o e-mail antes do primeiro acesso.',
    UserIsNotActiveException: 'Usuário desativado. Entre em contato com o suporte.',
    LoginAttemptFailedException: 'Muitas tentativas de login inválidas. Altere sua senha ou entre em contato com o suporte.',
    LoginFalse: 'Sign in',
    LoginTrue: 'Loading',
    RegisterFalse: 'Register',
    RegisterTrue: 'Loading',
    ForgotPasswordFalse: 'Forgot Password',
    ForgotPasswordTrue: 'Loading',
    ForgotPasswordInvalidException: 'Ops! E-mail inválido ou já existe uma solicitação para esse e-mail.',
    forgotPassword_ModelNotFoundException: 'Ops! Parece que esse usuário não existe.',
    forgotPassword_UniqueConstraintViolationException: 'Ops! Já existe uma solicitação de recuperação de senha para esse usuário. Verifique sua caixa de e-mail.',
    forgotPassword_Success: 'Foi enviado um link para recuperação de senha no e-mail: ',
    PasswordInvalidFormat: 'A senha não atende aos critérios mínimos de segurança.',
    PasswordsNotMatch: 'As senhas digitadas não são iguais.',
    EmailInvalid: 'E-mail inválido!',
    RegisterSuccess: 'Usuário cadastrado com suceso.',
    ResetPasswordFalse: 'Reset Password',
    ResetPasswordTrue: 'Loading',
    ResetPassword_Success: 'Password changed!',
    ResetPasswordTokenInvalidException: 'E-mail ou token inválido.',
    UpdateProfileFalse: 'Save',
    UpdateProfileTrue: 'Loading',
    UpdateProfileSuccess: 'Dados atualizados com sucesso.',
    update_UniqueConstraintViolationException: 'Ops! Já existe um cadastro com esse e-mail',
    master: 'Super Admin',
    firstNamedRequired: 'O campo de nome é obrigatório',
    roleNameRequired: 'Insira um nome para a regra',
    roleNameUniqueException: 'Já existe uma regra com esse nome',
    permissionNameRequired: 'Insira um nome para a permissão',
    permissionNameUniqueException: 'Já existe uma permissão com esse nome',
    Choose: 'Selecione',
    Upload: 'Enviar',
    Cancel: 'Cancelar',
    DragFiles: 'Arraste ou insira arquivos aqui para enviá-los',

}