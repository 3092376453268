export function validaEmail(email) {

  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // console.log(re.test(this.email) ? true : false);

  // console.log(email);
  // console.log(email.length);

  if (!email || !email.length) {
    return true
  }
  else {
    return re.test(email);
  }

}

export function validaPassword(password) {

  let re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[}{<>;:`,".!@^?~=+\-_*]).{8,30}$/;

  // console.log(re.test(this.email) ? true : false);

  // console.log(email);
  // console.log(email.length);

  if (!password || !password.length) {
    return true
  }
  else {
    return re.test(password);
  }

}