import Cookie from "@/service/cookie";
import { mapState } from 'vuex';

export default {

  computed: {
    ...mapState({
      user: state => state.user.user,
    }),

  },

    logout(){
        Cookie.deleteToken();
    }
}
