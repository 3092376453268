<template>
  <div class="row justify-content-center">
    <div class="h3 my-4">
      Lista de Tarefas
    </div>

    <form @submit.stop.prevent="" class="flex px-4 mb-3">
      <div class="input-group justify-content-center">
        <div class="col-3">
          <input id="inputTask" v-model="newTodo" class="form-control" :onKeypress="isNewTodo"
            placeholder="Digite o nome da tarefa..." type="text">
        </div>
        <div class="col-1">
          <button id="btnInputTask" type="submit" class="btn btn-primary" :disabled="isNewTodo">
            Adicionar
          </button>
        </div>
      </div>
    </form>

    <div class="flex items-center">
      <div class="cursor-pointer mr-2">
        <div class="w-full">
          <div class="ml-auto flex items-center justify-center">
            <button class="focus:outline-none"></button>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      Você ainda não possui nenhuma tarefa.
    </div>
    <SpinnerTodo :show="spinner.show" :altura="spinner.alt"></SpinnerTodo>

  </div>


</template>

<script>

import axios from 'axios';
import SpinnerTodo from '@/components/partials/SpinnerTodo.vue'

export default {
  name: 'TodoTasksPage',

  props: {

  },

  components: {
    SpinnerTodo,
  },

  data() {
    return {
      todo: {},
      spinner: {
        show: false,
        alt: 96,
      },
    }

  },

  created() {
    this.getTodos();
  },

  computed: {

  },

  methods: {

    getTodos() {

      this.spinner.show = true;

      axios.get(`todos/${this.$route.params.id}`)
        .then(response => {

          this.todo = response.data.data;

        })
        .catch((e) => {
          console.log(e);

        }).finally(() => {
          this.spinner.show = false;
        });
    },

  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span {
  margin: 40px 0 0;
  text-align: left;
}

.error-msg {
  margin-top: 5px;
  text-align: left;
  color: red;
  font-weight: bold;
  font-size: 15px;
}

.error-msg-small {
  font-size: 11px;
}

#btn-show-pwd:hover {
  color: #3a33b8;
  text-decoration: none;
}

span.error-msg {
  margin-left: 10px;
}
#inputTask:focus {
    outline: none !important;
    border:1px solid #03f00b;
    box-shadow: 0 0 10px #03f00b;
  }
#btnInputTask{
    background-color: #1dd423;
    border-color: #1dd423;
  }
</style>