import Cookie from "./cookie";
import axios from "axios";
import store from "@/store";
import message from '@/plugins/messages';

export default {

    redirectIfAuthenticated (to, from, next){
        const token = Cookie.getToken();
        let n;

        if(token){
            n = { name: 'home' }
        }

        next(n);
    },

    async redirectIfNotAuthenticated (to, from, next){
        const token = Cookie.getToken();
        let n;
        
        if(!token){
            n = { name: 'login' }
        }

        await axios.get('me').then(response => {
            if(!store?.state?.user?.id){

                store.commit('user/STORE_USER', response.data.data);
                store.commit('user/STORE_ROLES', response.data.roles);
            }
        }).catch(() => {
            // console.log(e);
            Cookie.deleteToken();
            next( { name: 'login' });
        });

        // Não permite acessar rotas inacessíveis pela barra do navegador
        let permission = false;
        // Só realiza a validação caso o usuário não seja 'Super Admin' 
        if (!store.state.user.roles.includes(message.master)) {
      
          if (to.meta.roles && to.meta.roles.length > 0) {
      
            try {
              store.state.user.roles.forEach(element => {
                if (to.meta.roles.includes(element)) {
                  permission = true
                }
      
                if (!permission) {
                    next({ name: 'error' });
                  }
      
              });
      
            } catch (error) { console.log('') }
      
          }
        }

        next(n);
    },
}