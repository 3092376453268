
<template>
    <div className="card">
        <h5>Empty Page</h5>
        <p>Use this page to start from scratch and place your custom content.
            <!-- {{ data.email }} -->
        </p>
    </div>
</template>
<script>
    // email: 'teste',
</script>