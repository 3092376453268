<template>
    <div
        class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <div
                style="border-radius: 30px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 20%, rgba(33, 150, 243, 0) 100%)">
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 30px">
                    <div class="text-center mb-5">
                        <img :src="layoutTheme" height="50" />
                        <div class="text-900 text-3xl font-medium my-3">{{ formTitle }}</div>
                    </div>

                    <div v-if="isForgotPassword">
                        <FormBodyForgotPwd></FormBodyForgotPwd>
                    </div>
                    <div v-if="isLogin">
                        <FormBodyLogin></FormBodyLogin>
                    </div>
                    <div v-if="isResetPassword">
                        <FormBodyResetPwd></FormBodyResetPwd>
                    </div>
                    <div v-if="isProfilePage">
                        <FormBodyProfile></FormBodyProfile>
                    </div>
                    <div v-if="isRegisterPage">
                        <FormBodyRegister></FormBodyRegister>
                    </div>
                    <div v-if="isVerifyEmailPage">
                        <FormBodyVerifyEmail></FormBodyVerifyEmail>
                    </div>

                    <div class="flex align-items-center justify-content-between mt-3 gap-5">
                        <a class="font-medium no-underline ml-2 text-right cursor-pointer" :href="linkTo"
                            style="color: var(--primary-color)">{{ linkTitle }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AppConfig simple />
</template>

<script>
import FormBodyForgotPwd from './FormBodyForgotPwd.vue';
import FormBodyLogin from './FormBodyLogin.vue';
import FormBodyResetPwd from './FormBodyResetPwd.vue';
import FormBodyProfile from './FormBodyProfile.vue';
import FormBodyRegister from './FormBodyRegister.vue';
import FormBodyVerifyEmail from './FormBodyVerifyEmail.vue';
import { useLayout } from '@/layout/composables/layout';

const layoutConfig = useLayout();

export default {
    name: 'FormAuth',
    data() {
        return {

        }
    },
    components: {
        FormBodyForgotPwd,
        FormBodyLogin,
        FormBodyResetPwd,
        FormBodyProfile,
        FormBodyRegister,
        FormBodyVerifyEmail,
    },
    props: {
        formTitle: {
            String,
            required: true,
            default: () => []
        },
        linkTitle: {
            String,
            required: false,
            default: () => []
        },
        linkTo: {
            String,
            required: false,
            default: () => []
        },
        viewTo: {
            String,
            required: true,
            default: () => []
        },
    },

    computed: {

        layoutTheme() {

            if ((layoutConfig.layoutConfig.darkTheme.value === true) || (layoutConfig.layoutConfig.darkTheme.value === 'dark'))
                return `/layout/images/logo-white.png`;
            else
                return `/layout/images/logo-dark.png`;
        },

        isLogin() {
            return this.viewTo === 'LoginPage';
        },

        isForgotPassword() {
            return this.viewTo === 'ForgotPasswordPage';
        },

        isResetPassword() {
            return this.viewTo === 'ResetPasswordPage';
        },

        isProfilePage() {
            return this.viewTo === 'ProfilePage';
        },

        isRegisterPage() {
            return this.viewTo === 'RegisterPage';
        },

        isVerifyEmailPage() {
            return this.viewTo === 'VerifyEmailPage';
        },
    },
}
</script>