<template>
  <FormAuth :formTitle="formTitle" :linkTo="linkTo" :linkTitle="linkTitle" :viewTo="viewTo"></FormAuth>
</template>

<script>
import FormAuth from '@/components/auth/FormAuth.vue';

export default {
  name: 'ForgotPasswordPage',
  components: {
    FormAuth,
  },

  data() {
    return {
      formTitle: 'Forgot Password?',
      linkTo: '/login',
      linkTitle: 'To login?',
      viewTo: 'ForgotPasswordPage',
    }
  },
};
</script>