<template>
    <div class="grid">
        <div class="col-12 xl:col-4">
            <div class="card">
                <TableRoles></TableRoles>
            </div>

            <div class="card">
                
            </div>
        </div>

        <div class="col-12 xl:col-8">
            <div class="card">
                <TablePermissions></TablePermissions>
            </div>

            <div class="card">

            </div>

        </div>

    </div>
</template>

<script>

import TableRoles from '../../components/table/TableRoles.vue'
import TablePermissions from '../../components/table/TablePermissions.vue'

export default {
    name: 'RolesPage',
    components: { TableRoles, TablePermissions },
    data() { return {} },
    created: function () { },
    methods: {},
    computed: {}
};

</script>

<style></style>
