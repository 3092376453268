<template>
    <Toolbar class="mb-2 align-middle">
        <template #start>
            <Button label="New" icon="pi pi-plus" outlined class="mr-2" @click="addItem()"
                style="border-radius: 5px;" />
        </template>
        <template #center>
            <h5 class="align-middle my-1">{{ title }}</h5>
        </template>

    </Toolbar>
    <DataTable class="border-4 align-middle" :value="data" :paginator="true" :rows="5"
        :rowsPerPageOptions="[5, 10, 20, 50]" :rowHover="true" showGridlines filterDisplay="menu" :loading="loading2"
        stripedRows removableSort tableStyle="min-width: 5rem">

        <template #header>
        </template>

        <template #empty> No customers found. </template>
        <template #loading> Loading customers data. Please wait. </template>
        <Column v-for="(field, idx) in fields" :key="idx" :field="`${field.key}`" :header="`${field.label}`"
            style="min-width: 5rem" :dataType="`${field.dataType}`" sortable :filter-field="`${field.key}`"
            :sort-field="`${field.key}`">
            <template #body="{ data }">
                {{ data[field.key] }}
            </template>
        </Column>

        <!-- <Column :exportable="false" style="min-width: 10rem">
            <template #body="{ data }">
                <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="editPermissions(data)" />
                <Button icon="pi pi-trash" outlined rounded severity="danger" @click="confirmDeleteItem(data)" />
            </template>
        </Column> -->

        <Column :exportable="false" style="min-width: 10rem">
            <template #body="{ data }">

                <div :class="`dropdown`">
                    <a href="#" data-bs-toggle="dropdown" aria-expanded="true">
                        <i class="pi pi-ellipsis-v" :class="`text-${themeMenu}`"></i>
                    </a>
                    <ul class="dropdown-menu" :style="`background-color: ${bkgMenu};`">
                        <li><Button @click="editPermissions(data)"
                                :style="`color: ${fontMenu}; background-color: ${bkgMenu}; border-color: ${bkgMenu};`"
                                href="#">Edit</Button>
                        </li>
                        <li><Button @click="confirmDeleteItem(data)"
                                :style="`color: ${fontMenu}; background-color: ${bkgMenu}; border-color: ${bkgMenu};`"
                                href="#">Delete</Button>
                        </li>
                    </ul>
                </div>

            </template>
        </Column>

        <template #footer>
            <!-- Total de registros: {{  }} -->
        </template>
    </DataTable>

    <Dialog v-model:visible="itemDialog" :style="{ width: '50rem' }" header="Role Details" :modal="true"
        class="p-fluid">
        <div class="mb-3">
            <label for="name" class="block text-900 text-xl font-medium mb-1">Nome</label>
            <InputGroup>
                <InputGroupAddon>
                    <i class="pi pi-user"></i>
                </InputGroupAddon>
                <InputText id="name" name="name" type="text" placeholder="Nome da regra" class="w-full md:h-3rem"
                    required v-model="item.name" />
            </InputGroup>
        </div>

        <div>
            <div v-if="!item.name" class="align-middle alert alert-danger w-full" role="alert">
                {{ messages.permissionNameRequired }}
            </div>
            <div v-if="axiosError" class="align-middle alert alert-danger w-full" role="alert">
                {{ messages.permissionNameUniqueException }}
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" text="" @click="hideDialog" />
            <Button label="Save" icon="pi pi-check" severity="success" text="" :disabled="!item.name"
                @click="storeItem()" />
        </template>
    </Dialog>

    <Dialog v-model:visible="deleteItemDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="item">Are you sure you want to delete the selected role?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" text @click="deleteItemDialog = false" />
            <Button label="Yes" icon="pi pi-check" text @click="deleteItem()" />
        </template>
    </Dialog>

    <Dialog v-model:visible="itemDialogPermissions" :style="{ width: '50rem' }" :header="`Permissôes: ${item.name}`"
        :modal="true" class="p-fluid">
        <!-- <ProgressSpinner animationDuration=".2s" v-show="spinnerShow" /> -->
        <div class="mb-3">
            <PickList v-model="picklistValue" dataKey="name" breakpoint="1400px"
                @move-to-source="insertPermission(item)" @move-to-target="removePermission(item)"
                moveAllToTargetButton="disabled">
                <template #sourceheader> Atual </template>
                <template #targetheader> Disponíveis </template>
                <template #item="slotProps">
                    <div class="">
                        <div class="">
                            <label class="mx-1">{{ slotProps.item.name }}</label>
                        </div>
                    </div>
                </template>
            </PickList>
        </div>
    </Dialog>

    <Dialog v-model:visible="itemSpinner.show" :style="{ width: '30rem' }" :modal="true"
        :header="`${itemSpinner.header}`" class="">
        <div class="mx-auto">
            <div><label>{{ itemSpinner.message }}</label></div>
            <div>
                <ProgressSpinner animationDuration=".2s" v-show="true" />
            </div>
        </div>
    </Dialog>
</template>

<script>

import axios from 'axios'
import message from '../../plugins/messages';
import { useLayout } from '@/layout/composables/layout';

const layoutConfig = useLayout();

export default {

    component: {

    },

    data() {
        return {
            testeste: null,
            picklistValue: [],
            selectedCity: '',
            loading2: false,
            itemDialog: false,
            deleteItemDialog: false,
            itemDialogPermissions: false,
            submitted: false,
            item: {},
            dataLocal: [],
            messages: message,
            data: [],
            fields: [
                { "key": "id", "label": "Id", "type": "int", "dataType": "numeric" },
                { "key": "name", "label": "Nome", "type": "str", "dataType": "text" },
            ],
            itemSpinner: {
                show: false,
                message: null,
                header: window.location.origin,
            },
            title: 'Permissões',
            axiosError: null,
        }
    },

    created: function () { this.getPermissions(); },
    beforeMount() { },

    mounted() { },
    props: {},
    computed: {

        themeMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'light' : 'dark';
        },

        fontMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'white' : 'black';
        },

        bkgMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'black' : 'white';
        },
    },

    methods: {

        hideDialog() {
            this.itemDialog = false;
            this.submitted = false;
            this.axiosError = null;
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.dataLocal.length; i++) {
                if (this.dataLocal.id[i] === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        addItem() {
            this.item = {};
            this.itemDialog = true;
        },
        editPermissions(data) {
            this.picklistValue = [];
            this.axiosError = null;
            this.item = { ...data };
            this.itemDialogPermissions = true;
            this.getRolePermissionAll(this.item);
        },

        confirmDeleteItem(data) {
            this.axiosError = null;
            this.item = { ...data };
            this.deleteItemDialog = true;
        },

        deleteItem() {
            this.submitted = true;
            this.deleteItemDialog = false;
            this.deletePermission(this.item);
        },

        getPermissions() {

            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Carregando dados...';

            axios.get('admin/permissions')
                .then(response => {
                    this.data = response.data.data;
                })
                .catch((e) => {
                    console.log(e);

                }).finally(() => {
                    this.itemSpinner.show = false;
                });
        },

        deletePermission(permission) {
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Deletando dados...';

            axios.delete(`admin/permission/${permission.id}`)
                .then(() => {
                    const idx = this.data.findIndex(o => o.id === permission.id);
                    this.data.splice(idx, 1);
                    this.itemDialog = false;
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Dados deletados com sucesso.', life: 3000 });
                })
                .catch((e) => {
                    console.log(e);
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.message, life: 5000 });
                }).finally(() => {
                    this.itemSpinner.show = false;
                });

        },

        storeItem() {
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Salvando dados...';
            const payload = {
                name: this.item.name,
            };

            axios.post('admin/create-permission', payload)
                .then(response => {

                    this.itemDialog = false;
                    // this.todos.push(response.data.data);//insere o item no final da lista todos: [],
                    this.data.unshift(response.data.data);//insere o item no começo da lista todos: [],
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Dados inseridos com sucesso.', life: 3000 });

                })
                .catch((e) => {
                    console.log(e);
                    this.axiosError = e.response.data.message;
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.message, life: 5000 });

                }).finally(() => {
                    this.itemSpinner.show = false;
                });
        },

        getRolePermissionAll(role) {

            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Carregando dados...';

            axios.get(`admin/no-permissions/${role.id}`)
                .then(response => {
                    this.picklistValue.push(response.data.rolePermissions);
                    this.picklistValue.push(response.data.data);
                })
                .catch((e) => {
                    console.log(e);

                }).finally(() => {
                    this.itemSpinner.show = false;
                });
        },

        insertPermission(role) {
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Salvando dados...';

            let rlePermissions = [];
            this.picklistValue[0].forEach(ins => {
                rlePermissions.push(ins.name);
            })

            const payload = {
                permission_name: rlePermissions,
                role_name: role.name,
            };

            axios.post('admin/permission-to-role', payload)
                .then(response => {
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: response.data.message, life: 3000 });
                })
                .catch((e) => {
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.error, life: 5000 });
                }).finally(() => {
                    this.itemSpinner.show = false;
                })
        },

        removePermission(role) {
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Salvando dados...';

            let rlePermissions = [];
            this.picklistValue[0].forEach(ins => {
                rlePermissions.push(ins.name);
            })

            const payload = {
                permission_name: rlePermissions,
                role_name: role.name,
            };

            axios.post('admin/remove-permission-from-role', payload)
                .then(response => {
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: response.data.message, life: 3000 });
                })
                .catch((e) => {
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.error, life: 5000 });
                }).finally(() => {
                    this.itemSpinner.show = false;
                })
        },

    }
}
</script>