<template>
    <div class="align-center">
        <div v-if="show">
            <img src="../../assets/img/spinner_color_primary.svg" :height="`${this.altura}`" class="">
        </div>
    </div>
</template>

<script>


export default {
    name: 'SpinnerTodo',

    data() {
        return {
            spinner: {
                display: this.show,
            },
        };
    },

    props: {
        show: Boolean,
        altura: Number,
    },

    computed: {

    },

    methods: {

    },
};
</script>