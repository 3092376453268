export default {

    namespaced: true,

    state: () => ({
        user: {},
        roles: [],
    }),

    mutations: {
        STORE_USER(state, user) {
            state.user = user;
        },
        STORE_ROLES(state, roles) {
            state.roles = roles;
        },
    },
    
    actions: {},
}