<template>
    <div class="grid">
        <div class="">
            <h5>{{ this.$route.meta.title }}</h5>
            <div class="card">
                <div class="filezone mb-4 flex align-items-center">
                    <FileUpload class="" outlined mode="advanced" type="file" id="files" ref="files" customUpload
                        multiple chooseLabel="">
                        <template #header="{ chooseCallback, clearCallback, uploadCallback, files }">
                            <div class="flex flex-wrap justify-between align-items-center flex-1 ">
                                <div class="flex gap-2 flex">
                                    <Button @click="chooseCallback()" icon="pi pi-plus" outlined class="text-center"
                                        style="border-radius: 5px;" :label="`${itensHeader.choose}`"></Button>
                                    <Button @click="submitFiles(files); uploadCallback()" icon="pi pi-upload" outlined
                                        class="text-center" style="border-radius: 5px;" :label="`${itensHeader.upload}`"
                                        severity="success" :disabled="!files || files.length === 0"></Button>
                                    <Button @click="clearCallback()" icon="pi pi-times" severity="danger" outlined
                                        class="text-center" :label="`${itensHeader.cancel}`" style="border-radius: 5px;"
                                        :disabled="!files || files.length === 0"></Button>
                                </div>
                            </div>
                        </template>

                        <!-- <template #content="{ files, removeFileCallback }">
                            <div class="flex flex-col gap-8 pt-4">
                                <div v-if="files.length > 0">
                                    <div class="flex gap-2">
                                        <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                                            class="p-4 border" style="border-radius: 5px;" severity="secondary">
                                            <div class="flex flex-column align-items-center justify-content-center">
                                                <img role="presentation" :alt="file.name" :src="file.objectURL"
                                                    height="100" class="" />
                                                <div>{{ file.name }}</div>
                                                <div>{{ formatSize(file.size) }}</div>
                                                <Badge value="Pending" severity="warning" />
                                                <Button icon="pi pi-times" @click="removeFileCallback(index)" outlined
                                                    rounded severity="danger" style="border-radius: 100%;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template> -->
                        <template #empty>
                            <div class="flex items-center justify-center">
                                <i class="pi pi-cloud-upload !border-2 !rounded-full text-3xl !text-muted-color" />
                                <p class="mx-4">{{ itensHeader.dragFile }}</p>
                            </div>
                        </template>
                    </FileUpload>
                </div>
            </div>
        </div>

    </div>
    <Dialog v-model:visible="itemProgressBar.show" :style="{ width: '30rem' }" :modal="true"
        :header="`${itemProgressBar.header}`" class="">
        <div class="mx-auto">
            <div class="my-2"><label>{{ itemProgressBar.message }}</label></div>
            <div class="my-2">
                <ProgressBar :value="progressUpload" class="md:w-20rem h-1rem w-full md:ml-auto"></ProgressBar>
            </div>
            <div>{{ formatSize(sizeUploaded, false) }} / {{ formatSize(totalSizeUpload, false) }} kB </div>
        </div>
    </Dialog>
</template>

<script>

import axios from 'axios';
import message from '../../plugins/messages';


export default {
    name: 'UploadFile',
    data() {
        return {
            itemProgressBar: {
                show: false,
                message: null,
                header: window.location.origin,
            },
            itensHeader: {
                choose: message.Choose,
                upload: message.Upload,
                cancel: message.Cancel,
                dragFile: message.DragFiles,
            },
            progressUpload: 0,
            totalSizeUpload: 0,
            sizeUploaded: 0,
        }
    },
    created: function () { },
    methods: {

        formatSize(fileSize, extension) {

            let size = fileSize / 1024;

            if (!extension) {
                return size.toFixed(0);
            }

            size = size.toFixed(2) + ' kB'
            return size;
        },
        submitFiles(files_) {

            this.progressUpload = 0;
            this.itemProgressBar.show = true;
            this.itemProgressBar.message = 'Enviando arquivos...';

            for (let i = 0; i < files_.length; i++) {
                if (files_[i].id) {
                    continue;
                }
                let formData = new FormData();
                formData.append('file', files_[i]);
                axios.post('upload-file',
                    formData,
                    {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;

                            this.totalSizeUpload = total;
                            this.sizeUploaded = loaded;

                            let precentage = Math.floor((loaded * 100) / total);
                            this.progressUpload = precentage;
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    },

                ).then(function (data) {
                    files_[i].id = data['data']['id'];
                    files_.splice(i, 1, files_[i]);
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Arquivos enviados com sucesso.', life: 3000 });
                }
                    .bind(this))
                    .catch((e) => {
                        console.log(e);
                    }).finally(() => {
                        this.itemProgressBar.show = false;
                    });
            }
        }
    },
    computed: {}
};

</script>

<style></style>
