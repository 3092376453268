import { createRouter, createWebHistory } from 'vue-router'

import AppLayout from '@/layout/AppLayout.vue'

import LoginView from '../views/auth/LoginView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'
import VerifyEmailView from '@/views/auth/VerifyEmailView.vue'
import ForgotPasswordView from '@/views/auth/ForgotPasswordView.vue'
import ResetPasswordView from '@/views/auth/ResetPasswordView.vue'
import ProfileView from '@/views/auth/ProfileView.vue'
import ErrorView from '@/views/auth/ErrorView.vue'

import Dashboard from '@/views/Dashboard.vue'

import AdminUsers from '@/views/admin/AdminUsers.vue'
import AdminRoles from '@/views/admin/AdminRoles.vue'

import UploadFile from '@/views/demo/UploadFile.vue'
import MediaView from '@/views/demo/MediaView.vue'

import TodoTasksView from '@/views/todo/TodoTasksView.vue'

import Guard from '@/service/middleware'

import UploadFileView from '@/views/examples/UploadFileView.vue'
import NotFoundView from '@/views/examples/NotFoundView.vue'
import TableExampleView from '@/views/examples/TableExampleView.vue'
import Empty from '@/views/examples/Empty.vue'

import AjusteFeriados from '@/views/mv/AjusteFeriados.vue'
import AjusteHorPonta from '@/views/mv/AjusteHorPonta.vue'
import AjusteTotalLamp from '@/views/mv/AjusteTotalLamp.vue'
import MedicaoHorCon from '@/views/mv/MedicaoHorCon.vue'
import MedicaoHorHmm from '@/views/mv/MedicaoHorHmm.vue'
import MedicaoPotencia from '@/views/mv/MedicaoPotencia.vue'
import MedidorEnergia from '@/views/mv/MedidorEnergia.vue'
import MedidorTempo from '@/views/mv/MedidorTempo.vue'
import UnidadesConsum from '@/views/mv/UnidadesConsum.vue'

const routes = [

  {
    path: '/', component: AppLayout,
    beforeEnter: Guard.redirectIfNotAuthenticated,

    children: [
      // { path: '', name: 'home', component: Dashboard, meta: {title: 'Início', roles: ['Admin'], path: '/'}},
      { path: '', name: 'home', component: Dashboard, meta: {title: 'Início', path: '/'}},
      { path: 'perfil', name: 'profile', component: ProfileView },
      { path: 'error', name: 'error', component: ErrorView },
      { path: 'todo/:id', name: 'todo-tasks', component: TodoTasksView },
      { path: 'upload-file', name: 'upload-file', component: UploadFileView },
      // { path: 'all-todos', name: 'all-todos', component: TodosTableView },
      { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFoundView, meta: {title: 'Início'} },
      { path: 'mv/feriados', name: 'feriados', component: AjusteFeriados },
      { path: 'mv/horaponta', name: 'horaponta', component: AjusteHorPonta },
      { path: 'mv/lampadastotal', name: 'lampadastotal', component: AjusteTotalLamp },
      { path: 'mv/medicaohoracon', name: 'medicaohoracon', component: MedicaoHorCon },
      { path: 'mv/medicaohorahmm', name: 'medicaohorahmm', component: MedicaoHorHmm },
      { path: 'mv/medicaopotencia', name: 'medicaopotencia', component: MedicaoPotencia },
      { path: 'mv/medidorenergia', name: 'medidorenergia', component: MedidorEnergia },
      { path: 'mv/medidortempo', name: 'medidortempo', component: MedidorTempo },
      { path: 'mv/unidadesconsumidoras', name: 'unidadesconsumidoras', component: UnidadesConsum },
      { path: 'mv/downloadapp', name: 'downloadapp', component: Empty },
      { path: 'demo/upload', name: 'upload', component: UploadFile, meta: {title: 'Upload File'} },
      { path: 'demo/media', name: 'media', component: MediaView, meta: {title: 'Medias'} },
    ]
  },

  {
    path: '/', component: AppLayout,
    beforeEnter: Guard.redirectIfNotAuthenticated,

    children: [
      { path: 'table-example', name: 'table-example', component: TableExampleView, meta: { roles: ['Super Admin'], path: '/table-example' } },
      { path: 'register', name: 'register', component: RegisterView, meta: {title: 'Novo usuário', roles: ['Super Admin'], path: '/register' } },
      { path: 'users', name: 'users', component: AdminUsers, meta: {title: 'Usuários', roles: ['Super Admin'], path: '/users' } },
      { path: 'roles', name: 'roles', component: AdminRoles, meta: {title: 'Roles', roles: ['Super Admin'], path: '/roles' } },
    ]
  },

  {
    path: '/',
    beforeEnter: Guard.redirectIfAuthenticated,

    children: [
      { path: '/login', name: 'login', component: LoginView },
      { path: '/forgot-password', name: 'forgot-password', component: ForgotPasswordView },
    ]
  },

  {
    path: '/',

    children: [
      { path: '/reset-password', name: 'reset-password', component: ResetPasswordView },
      { path: '/verify-email', name: 'verify-email', component: VerifyEmailView },
    ]
  },

  // {
  //   path: '/login',
  //   beforeEnter: Guard.redirectIfAuthenticated,
  //   children: [
  //     { path: '', name: 'login', component: LoginView },
  //   ]
  // },
  
  // {
  //   path: '/forgot-password',
  //   beforeEnter: Guard.redirectIfAuthenticated,
  //   children: [
  //     { path: '', name: 'forgot-password', component: ForgotPasswordView },
  //   ]
  // },

  // {
  //   path: '/reset-password',
  //   children: [
  //     { path: '', name: 'reset-password', component: ResetPasswordView },
  //   ]
  // },

  // {
  //   path: '/verify-email',
  //   children: [
  //     { path: '', name: 'verify-email', component: VerifyEmailView },
  //   ]
  // },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
