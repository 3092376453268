<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-2 align-middle">
                    <template #start>
                        <Button label="New" icon="pi pi-plus" outlined class="mr-2" style="border-radius: 5px;"
                            @click="addItem()" />
                        <!-- <a href="/register">
                            <Button label="New" icon="pi pi-plus" outlined class="mr-2" style="border-radius: 5px;" @click="addItem()"/>
                        </a> -->
                        <!-- <<router-link to="/">tsds</router-link> -->
                        <Button label="Export" icon="pi pi-upload" outlined @click="exportCSV($event)"
                            style="border-radius: 5px;" />
                    </template>
                    <template #center>
                        <h5 class="align-middle my-1">{{ title }}</h5>
                    </template>
                    <template #end>
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="mr-2" outlined
                            style="border-radius: 5px;" @click="clearFilter()" />
                        <IconField iconPosition="left">
                            <InputIcon class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search"
                                style="width: 12rem" />
                        </IconField>
                    </template>
                </Toolbar>
                <DataTable class="border-4" :value="data" :paginator="true" :rows="10"
                    :rowsPerPageOptions="[10, 20, 50]" sortField="id" :sortOrder="-1" :rowHover="true" showGridlines
                    filterDisplay="menu" :loading="loading2" stripedRows removableSort
                    :globalFilterFields="`${globalFilters}`" tableStyle="min-width: 50rem" :filters="filters"
                    v-model:filters="filters">

                    <template #header>
                    </template>

                    <template #empty> No customers found. </template>
                    <template #loading> Loading customers data. Please wait. </template>
                    <Column v-for="(field, idx) in fields" :key="idx" :field="`${field.key}`" :header="`${field.label}`"
                        style="min-width: 12rem" :dataType="`${field.dataType}`" sortable :filter-field="`${field.key}`"
                        :sort-field="`${field.key}`">
                        <template #body="{ data }">

                            <i v-if="field.type === 'boolean'" class="pi"
                                :class="{ 'pi-check-circle text-green-500 ': data[field.key], 'pi-times-circle text-red-500': !data[field.key] }"></i>

                            <div v-if="field.type === 'date'">
                                {{ formatDate(data[field.key]) }}
                            </div>
                            <div v-else-if="field.type !== 'boolean'">
                                {{ data[field.key] }}
                            </div>
                        </template>
                        <template v-if="field.dataType === 'numeric'" #filter="{ filterModel }">
                            <InputText type="numeric" v-model="filterModel.value" class="p-column-filter"
                                :placeholder="`Search by ${field.label}`" />
                        </template>
                        <template v-else-if="field.dataType === 'text'" #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                :placeholder="`Search by ${field.label}`" />
                        </template>
                        <template v-else-if="field.dataType === 'date'" #filter="{ filterModel }">
                            <Calendar type="text" v-model="filterModel.value" dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </template>

                        <template v-else-if="field.dataType === 'boolean' && field.key=== 'status'"
                            #filter="{ filterModel }">
                            <label for="status" class="font-bold my-2"> Filtrar </label>
                            <br>
                            <Checkbox v-model="filterModel.value" :indeterminate="filterModel.value === false" binary
                                inputId="status" />
                            <label for="status" class="font-bold mx-2">
                                {{filterModel.value ? 'Usuários ativos' : 'Usuários desativados'}} </label>
                        </template>

                        <template v-else-if="field.dataType === 'boolean' && field.key=== 'email_verified_at'"
                            #filter="{ filterModel }">
                            <label for="email_verified_at" class="font-bold my-2"> Filtrar </label>
                            <br>
                            <Checkbox v-model="filterModel.value" :indeterminate="filterModel.value === null" binary
                                inputId="email_verified_at" />
                            <label for="email_verified_at" class="font-bold mx-2">
                                {{filterModel.value ? 'E-mail verificado' : 'E-mail não verificado'}} </label>
                        </template>
                    </Column>
                    <Column :exportable="false" style="min-width: 5rem">
                        <template #body="{ data }">

                            <!-- <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="editItem(data)" />
                            <Button icon="pi pi-trash" outlined rounded severity="danger"
                                @click="confirmDeleteItem(data)" /> -->

                            <div :class="`dropdown`">
                                <a href="#" data-bs-toggle="dropdown" aria-expanded="true">
                                    <i class="pi pi-ellipsis-v" :class="`text-${themeMenu}`"></i>
                                </a>
                                <ul class="dropdown-menu" :style="`background-color: ${bkgMenu};`">
                                    <li><Button @click="editItem(data)"
                                            :style="`color: ${fontMenu}; background-color: ${bkgMenu}; border-color: ${bkgMenu};`"
                                            href="#">Edit</Button>
                                    </li>
                                    <li><Button @click="confirmDeleteItem(data)"
                                            :style="`color: ${fontMenu}; background-color: ${bkgMenu}; border-color: ${bkgMenu};`"
                                            href="#">Delete</Button>
                                    </li>
                                </ul>
                            </div>

                        </template>
                    </Column>

                    <template #footer>
                        <!-- Total de registros: {{  }} -->
                    </template>
                </DataTable>

                <Dialog v-model:visible="itemDialog" :style="{ width: '50rem' }" header="User Details" :modal="true"
                    class="p-fluid">
                    <div class="mb-3">
                        <label for="firstName" class="block text-900 text-xl font-medium mb-1">First Name</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="firstName" name="first-name" type="text" placeholder="First Name"
                                class="w-full md:h-3rem" required v-model="item.first_name" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="lastName" class="block text-900 text-xl font-medium mb-1">Last Name</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="lastName" name="last-name" type="text" placeholder="Last Name"
                                class="w-full md:h-3rem" required="false" v-model="item.last_name" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="email" class="block text-900 text-xl font-medium mb-1">Email</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="email" type="text" placeholder="Email address" class="w-full md:h-3rem"
                                required v-model="item.email" />
                        </InputGroup>
                    </div>

                    <div class="mb-5">
                        <label for="status" class="block text-900 text-xl font-medium mb-1">Status</label>
                        <Checkbox v-model="item.status" binary inputId="status" />
                        <label class="mx-2">{{ item.status ?
                                'Usuário ativo. Clique para desativar' : "Usuário desativado.Clique para ativar" }}</label>
                    </div>

                    <div class="mb-3">
                        <label for="status" class="block text-900 text-xl font-medium mb-1">Pemissões</label>
                        <PickList v-model="picklistValue" dataKey="name" breakpoint="1400px"
                            @move-to-source="insertRole(item)" @move-to-target="removeRole(item)"
                            moveAllToTargetButton="disabled">
                            <template #sourceheader> Atual </template>
                            <template #targetheader> Disponíveis </template>
                            <template #item="slotProps">
                                <div class="product-item">

                                    <div class="product--detail">
                                        <label class="mx-1">{{ slotProps.item.name }}</label>
                                    </div>

                                </div>
                            </template>
                        </PickList>

                    </div>

                    <!-- =========================================================== -->
                    <div>
                        <div v-if="!item.email || !checkEmail(item.email)"
                            class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.EmailInvalid }}
                        </div>
                        <div v-if="!item.first_name" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.firstNamedRequired }}
                        </div>
                        <div v-if="axiosError" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.UserHasBeenTakenException }}
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Cancel" icon="pi pi-times" text="" @click="hideDialog" />
                        <Button label="Save" icon="pi pi-check" severity="success" text=""
                            :disabled="!item.email || !checkEmail(item.email)" @click="saveItem()" />
                    </template>
                </Dialog>


                <!-- =========================Dialog new user===================== -->
                <Dialog v-model:visible="itemDialog_new_user" :style="{ width: '50rem' }" header="Create User"
                    :modal="true" class="p-fluid">
                    <div class="mb-3">
                        <label for="firstName" class="block text-900 text-xl font-medium mb-1">First Name</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="firstName" name="first-name" type="text" placeholder="First Name"
                                class="w-full md:h-3rem" required v-model="item.first_name" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="lastName" class="block text-900 text-xl font-medium mb-1">Last Name</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="lastName" name="last-name" type="text" placeholder="Last Name"
                                class="w-full md:h-3rem" required="false" v-model="item.last_name" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="email" class="block text-900 text-xl font-medium mb-1">Email</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="email" type="text" placeholder="Email address" class="w-full md:h-3rem"
                                required v-model="item.email" />
                        </InputGroup>
                    </div>

                    <div class="mb-3">
                        <label for="password" class="block text-900 text-xl font-medium mb-1">Password</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-key"></i>
                            </InputGroupAddon>
                            <Password v-model="item.password" placeholder="Password" :toggleMask="true"
                                @click="checkPassword(item.password, item.repassword)"
                                class="w-full md:w-30rem md:h-3rem" :feedback="true" required>
                                <template #header>
                                    <div class="font-semibold text-xm mb-4">Pick a password</div>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <ul class="pl-2 ml-2 my-0 leading-normal">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>At least one non numeric character</li>
                                        <li>Minimum 8 characters</li>
                                    </ul>
                                </template>
                            </Password>
                        </InputGroup>
                    </div>

                    <div class="mb-5">
                        <label for="repassword" class="block text-900 text-xl font-medium mb-1">Retype</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-key"></i>
                            </InputGroupAddon>
                            <Password v-model="item.repassword" placeholder="Retype Password" :toggleMask="true"
                                class="w-full md:w-30rem md:h-3rem" :feedback="true" required
                                @click="reCheckPassword(item.password, item.repassword)">
                                <template #header>
                                    <div class="font-semibold text-xm mb-4">Pick a password</div>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <ul class="pl-2 ml-2 my-0 leading-normal">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>At least one non numeric character</li>
                                        <li>Minimum 8 characters</li>
                                    </ul>
                                </template>
                            </Password>
                        </InputGroup>
                    </div>

                    <!-- =========================================================== -->
                    <div>
                        <div v-if="!item.first_name" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.firstNamedRequired }}
                        </div>
                        <div v-if="!item.email || !checkEmail(item.email)"
                            class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.EmailInvalid }}
                        </div>

                        <div v-if="axiosError" class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.UserHasBeenTakenException }}
                        </div>
                        <div v-if="!checkPassword(item.password, item.repassword)"
                            class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.PasswordInvalidFormat }}
                        </div>

                        <div v-if="!reCheckPassword(item.password, item.repassword)"
                            class="align-middle alert alert-danger w-full" role="alert">
                            {{ messages.PasswordsNotMatch }}
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Cancel" icon="pi pi-times" text="" @click="hideDialog" />
                        <Button label="Save" icon="pi pi-check" severity="success" text=""
                            :disabled="!item.email || !checkEmail(item.email) || !checkPassword(item.password, item.repassword)"
                            @click="storeUserItem()" />
                    </template>
                </Dialog>

                <!-- =========================Dialog new user===================== -->

                <Dialog v-model:visible="deleteItemDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="item">Are you sure you want to delete the selected user?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" text @click="deleteItemDialog = false" />
                        <Button label="Yes" icon="pi pi-check" text @click="deleteItem()" />
                    </template>
                </Dialog>

            </div>
        </div>
    </div>
    <Dialog v-model:visible="itemSpinner.show" :style="{ width: '30rem' }" :modal="true"
        :header="`${itemSpinner.header}`" class="">
        <div class="mx-auto">
            <div><label>{{ itemSpinner.message }}</label></div>
            <div>
                <ProgressSpinner animationDuration=".2s" v-show="true" />
            </div>
        </div>
    </Dialog>
</template>

<script>

import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Calendar from 'primevue/calendar'
import axios from 'axios'
import FormBodyRegister from '../auth/FormBodyRegister.vue'
import message from '../../plugins/messages';
const util = require('../../plugins/util');
import { useLayout } from '@/layout/composables/layout';

const layoutConfig = useLayout();

export default {

    component: {
        Calendar,
        FormBodyRegister,
    },

    data() {
        return {
            filters: [],
            loading2: false,
            globalFilters: [],
            itemDialog: false,
            itemDialog_new_user: false,
            deleteItemDialog: false,
            submitted: false,
            item: {},
            dataLocal: [],
            picklistValue: [],
            messages: message,
            newUser: null,
            data: [],
            fields: [
                { "key": "id", "label": "Id", "type": "int", "dataType": "numeric" },
                { "key": "first_name", "label": "Nome", "type": "str", "dataType": "text" },
                { "key": "last_name", "label": "Sobrenome", "type": "str", "dataType": "text" },
                { "key": "email", "label": "E-mail", "type": "str", "dataType": "text" },
                { "key": "email_verified_at", "label": "E-mail verificado", "type": "boolean", "dataType": "boolean" },
                { "key": "status", "label": "Ativo?", "type": "boolean", "dataType": "boolean" },
            ],
            title: 'Usuários Cadastrados',
            spinner: {
                show: false,
                alt: 96,
            },
            axiosError: null,
            itemSpinner: {
                show: false,
                message: null,
                header: window.location.origin,
            },
        }
    },

    created: function () {
        this.getUsers();
    },
    beforeMount() {
        this.initFilters();
        this.setGlobalFilters();
    },

    mounted() {

    },
    props: {},
    computed: {

        themeMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'light' : 'dark';
        },

        fontMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'white' : 'black';
        },

        bkgMenu() {
            return (layoutConfig.layoutConfig.darkTheme.value === true || layoutConfig.layoutConfig.darkTheme.value === 'dark') ? 'black' : 'white';
        },
    },

    methods: {

        formatDate(value) {
            let date = new Date(value);
            return date.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                first_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                last_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                updated_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                email_verified_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },

            };
        },
        setGlobalFilters() {
            this.dataLocal.forEach(element => {
                this.globalFilters.push(element.key);
            });
        },
        clearFilter() {
            this.initFilters();
        },
        hideDialog() {
            this.itemDialog = false;
            this.itemDialog_new_user = false;
            this.submitted = false;
            this.axiosError = null;
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.dataLocal.length; i++) {
                if (this.dataLocal.id[i] === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        addItem() {
            this.item = {};
            this.itemDialog_new_user = true;
        },
        editItem(data) {

            this.picklistValue = [];
            this.axiosError = null;
            this.item = { ...data };
            this.itemDialog = true;
            this.getUserRoleAll(this.item);
        },
        saveItem() {
            this.submitted = true;
            if (this.item.id) {
                this.data[this.findIndexById(this.item.id)] = this.item;
                this.updateUser(this.item);
            }
        },

        storeUserItem() {
            this.submitted = true;
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Salvando dados...';
            const payload = {
                first_name: this.item.first_name,
                last_name: this.item.last_name || "",
                password: this.item.password,
                email: this.item.email,

            };

            axios.post('register', payload)
                .then(response => {

                    this.newUser = response.data.data;
                    // this.todos.push(response.data.data);//insere o item no final da lista todos: [],
                    
                    //Para filtros de data funcionarem
                    this.newUser.created_at = new Date(this.newUser.created_at);
                    this.newUser.updated_at = new Date(this.newUser.updated_at);

                    if (this.newUser.email_verified_at) {
                        this.newUser.email_verified_at = true;
                    } else {
                        this.newUser.email_verified_at = false;
                    }

                    this.data.unshift(this.newUser);//insere o item no começo da lista users: [],
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Dados inseridos com sucesso.', life: 3000 });
                    this.itemDialog_new_user = false;

                })
                .catch((e) => {
                    console.log(e);
                    this.axiosError = e.response.data.message;
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.message, life: 5000 });

                }).finally(() => {
                    this.itemSpinner.show = false;
                });

            // console.log(this.item);

            // setTimeout(() => { this.itemSpinner.show = false }, 2000);
        },

        confirmDeleteItem(data) {
            this.axiosError = null;
            this.item = { ...data };
            this.deleteItemDialog = true;
        },

        deleteItem() {
            this.submitted = true;
            this.deleteItemDialog = false;
            this.deleteUser(this.item);
        },

        checkEmail(email) {
            return util.validaEmail(email);
        },

        checkPassword(password, repassword) {
            // return ((util.validaPassword(password) && util.validaPassword(repassword)) && (password === repassword)
            return ((util.validaPassword(password) && util.validaPassword(repassword)));
        },

        reCheckPassword(password, repassword) {
            return (password === repassword);
        },

        getUserRoleAll(user) {
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Carregando dados...';

            axios.get(`admin/user/no-roles/${user.id}`)
                .then(response => {
                    console.log(response.data.data);
                    console.log(response.data.allRoles);
                    console.log(response.data.userRoles);

                    this.picklistValue.push(response.data.userRoles);
                    this.picklistValue.push(response.data.data);

                })
                .catch((e) => {
                    console.log(e);

                }).finally(() => {
                    this.itemSpinner.show = false;
                });
        },

        getUsers() {
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Carregando dados...';

            axios.get('admin/users')
                .then(response => {

                    this.data = response.data.data;

                    this.data.forEach(element => { //Para filtros de data funcionarem
                        element.created_at = new Date(element.created_at);
                        element.updated_at = new Date(element.updated_at);

                        if (element.email_verified_at) {
                            element.email_verified_at = true;
                        } else {
                            element.email_verified_at = false;
                        }
                    });

                })
                .catch((e) => {
                    console.log(e);

                }).finally(() => {
                    this.itemSpinner.show = false;
                });
        },

        updateUser(user) {
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Salvando dados...';

            const payload = {
                first_name: user.first_name,
                last_name: user.last_name || "",
                email: user.email,
                status: user.status,
            };

            axios.put(`admin/user/${user.id}`, payload)
                .then(() => {
                    const idx = this.data.findIndex(o => o.id === user.id);
                    this.data[idx] = user;
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Dados atualizados com sucesso.', life: 3000 });
                    this.itemDialog = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.axiosError = e.response.data.message;

                }).finally(() => {
                    this.itemSpinner.show = false;
                });

        },

        deleteUser(user) {
            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Salvando dados...';

            axios.delete(`admin/user/${user.id}`)
                .then(() => {
                    const idx = this.data.findIndex(o => o.id === user.id);
                    this.data.splice(idx, 1);
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Dados deletados com sucesso.', life: 3000 });
                })
                .catch((e) => {
                    console.log(e);
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.message, life: 5000 });


                }).finally(() => {
                    this.itemSpinner.show = false;
                });

        },

        // getUserRoles(user) {
        //     this.spinner.show = true;
        //     axios.get(`admin/user/roles/${user.id}`)
        //         .then(response => {
        //             this.userRoles = response.data.data;
        //             this.getRoles();
        //         })
        //         .catch((e) => {
        //             console.log(e);
        //             this.axiosError = e.response.data.message;

        //         }).finally(() => {
        //             this.spinner.show = false;
        //         });

        // },

        // getRoles() {
        //     this.spinner.show = true;
        //     axios.get('admin/roles')
        //         .then(response => {

        //             this.allRoles = response.data.data;
        //             let unusedRoles = [];
        //             let count = 0;

        //             this.allRoles.forEach(role => {
        //                 count = 0;
        //                 this.userRoles.forEach(userRole => {
        //                     if (userRole.name === role.name) { count++; }
        //                 })
        //                 if (count == 0) { unusedRoles.push(role); }
        //             })

        //             this.picklistValue.push(this.userRoles);
        //             this.picklistValue.push(unusedRoles);
        //         })
        //         .catch((e) => {
        //             console.log(e);

        //         }).finally(() => {
        //             this.spinner.show = false
        //         });
        // },

        insertRole(user) {

            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Salvando dados...';

            let usrRoles = [];
            this.picklistValue[0].forEach(ins => {
                usrRoles.push(ins.name);
            })

            const payload = {
                role_name: usrRoles,
                user_id: user.id,
            };

            axios.post('admin/role-to-user', payload)
                .then(response => {
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: response.data.message, life: 3000 });
                })
                .catch((e) => {
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.error, life: 5000 });
                }).finally(() => {
                    this.itemSpinner.show = false;
                })
        },

        removeRole(user) {

            this.itemSpinner.show = true;
            this.itemSpinner.message = 'Salvando dados...';

            let rmRoles = [];
            this.picklistValue[1].forEach(rm => {
                rmRoles.push(rm.name);
            })

            const payload = {
                role_name: rmRoles,
                user_id: user.id,
            };


            axios.post('admin/remove-role-from-user', payload)
                .then(response => {
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: response.data.message, life: 3000 });
                })
                .catch((e) => {
                    this.$toast.add({ severity: 'error', summary: 'Error Message', detail: e.response.data.error, life: 5000 });
                }).finally(() => {
                    this.itemSpinner.show = false;
                })
        },
    }
}
</script>
<style></style>