<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <form id="forgotPassword" @submit.prevent.stop="forgotPassword">
                    <div v-if="response.message" class="alert alert-danger" role="alert">
                        {{ response.message }}
                    </div>
                    <div class="mb-3">
                        <label for="email" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="email" type="text" placeholder="Email address"
                                class="w-full md:w-30rem md:h-3rem" required style="padding: 1rem" v-model="email" />
                        </InputGroup>
                    </div>

                    <Button type="submit" style="border-radius: 5px;" :label="spinner.message"
                        class="w-full p-3 md:h-3rem" :disabled="spinner.forgotPassword || !checkEmail()" id="btn-login">
                        <img v-if="spinner.forgotPassword" width="30" src="../../assets/img/spinner_color_white.svg"
                            alt="" class="">
                    </Button>

                    <div v-if="!checkEmail()" class="align-middle alert alert-danger w-full" role="alert">
                        {{ messages.EmailInvalid }}
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>

import axios from 'axios';
import message from '../../plugins/messages';
const util = require('../../plugins/util');

export default {
    name: 'ForgotPwd',
    components: {
    },

    data() {
        return {
            email: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                forgotPassword: false,
                message: message.ForgotPasswordFalse,
            },
            messages: message,
        }
    },

    methods: {
        forgotPassword() {

            const payload = {
                email: this.email,
            };

            this.resetResponse();

            axios.post('forgot-password', payload)
                .then(response => {

                    console.log(response.data);

                    this.response.color = 'green';
                    this.response.message = message.forgotPassword_Success + this.email;
                    this.spinner.message = message.ForgotPasswordFalse;

                    this.clearForm()
                })
                .catch((e) => {
                    console.log(e);
                    const errorCode = e?.response?.data?.error || 'ServerError';
                    this.response.color = 'red';
                    this.response.message = message[errorCode];

                    this.spinner.message = message.ForgotPasswordFalse;

                }).finally(() => {
                    this.spinner.forgotPassword = false;
                })

        },

        checkEmail() {
            return util.validaEmail(this.email);
        },

        resetResponse() {
            this.response.color = '';
            this.response.message = '';

            this.spinner.forgotPassword = true;
            this.spinner.message = message.ForgotPasswordTrue;
        },

        clearForm() {
            this.email = '';
        },

    },

};
</script>