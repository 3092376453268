<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <form id="login" @submit.prevent.stop="login">
                    <div v-if="response.message" class="alert alert-danger" role="alert">
                        {{ response.message }}
                    </div>
                    <div class="mb-3">
                        <label for="email" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText id="email" type="text" placeholder="Email address"
                                class="w-full md:w-30rem md:h-3rem" required style="padding: 1rem" v-model="email" />
                        </InputGroup>
                    </div>

                    <div class="mb-5">
                        <label for="password" class="block text-900 text-xl font-medium mb-2">Password</label>
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-key"></i>
                            </InputGroupAddon>
                            <Password v-model="password" placeholder="Password" :toggleMask="true"
                                class="w-full md:w-30rem md:h-3rem" :feedback="false" required />
                        </InputGroup>
                    </div>

                    <Button type="submit" style="border-radius: 5px;" :label="spinner.message"
                        class="w-full p-3 md:h-3rem mb-3" :disabled="spinner.login || !checkEmail()" id="btn-login"
                        >
                        <img v-if="spinner.login" width="30" src="../../assets/img/spinner_color_white.svg" alt=""
                            class="">
                    </Button>

                    <div v-if="!checkEmail()" class="align-middle alert alert-danger w-full" role="alert">
                        {{ messages.EmailInvalid }}
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>

import axios from 'axios';
import message from '../../plugins/messages';
const util = require('../../plugins/util');
import Cookie from "../../service/cookie";
import { ThemeService } from '@/service/ThemeService';

const themeService = new ThemeService();

export default {
    name: 'LoginFormPage',
    components: {

    },

    data() {
        return {
            email: '',
            password: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                login: false,
                message: message.LoginFalse,
            },
            showPassword: false,
            messages: message,
        }
    },

    methods: {
        login() {

            const payload = {
                email: this.email,
                password: this.password,
            };

            // Login na Api do MV App
            // const payload2 = {
            //   password: "Newark00*",
            //   email: "ma@ma",
            //   id: 0,
            //   nome: "1.7",
            //   projectId: 0,
            //   tipo: "string",
            //   status: true,
            //   token: "string",
            // };

            this.resetResponse();

            // axios.post('Login', payload) //MV App
            axios.post('login', payload)
                .then(response => {

                    this.clearForm()
                    // console.log(response.data);

                    // stop()
                    const token = `${response.data.token_type} ${response.data.access_token}`;
                    // Cookie.set('_todolist_token', token, { expires: 30 });
                    Cookie.setToken(token);
                    this.$store.commit('user/STORE_USER', response.data.data);
                    this.$store.commit('user/STORE_ROLES', response.data.roles);
                    // console.log(response.data.roles[0])

                    let theme = Cookie.getTheme()[0];
                    let theme_mode = Cookie.getTheme()[1];

                    if (!(theme && theme_mode))
                        themeService.getMePreference().then(response => {
                            try {
                                let theme = response.data.data.theme;
                                let theme_mode = response.data.data.theme_mode;

                                Cookie.setTheme(theme, theme_mode);

                            } catch (error) {
                                console.log(error);
                            }
                        });

                    this.$router.push({ name: 'home' });

                })
                .catch((e) => {
                    console.log(e);
                    const errorCode = e?.response?.data?.error || 'ServerError';
                    this.response.color = 'red';
                    this.response.message = message[errorCode];

                    this.spinner.message = message.LoginFalse;

                }).finally(() => {
                    this.spinner.login = false;
                })

        },

        checkEmail() {
            return util.validaEmail(this.email);
        },

        resetResponse() {
            this.response.color = '';
            this.response.message = '';

            this.spinner.login = true;
            this.spinner.message = message.LoginTrue;
        },

        toggleShow() {
            this.showPassword = !this.showPassword;
        },

        clearForm() {
            this.email = '';
            this.password = '';
        },

    },

};
</script>