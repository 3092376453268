<template>
    <form id="verifyemail">

        <div class="container text-center" style="width: 350px;">

            <h1 v-if="email != ''" class="h3 mb-3 fw-normal underline"><strong>{{ email }}</strong></h1>

            <img v-if="spinner.verify_email" width="50" src="../../assets/img/spinner_color_primary.svg" alt=""
                class="">

            <div v-if="response.message && response.color == 'red'" class="alert alert-danger" role="alert">
                {{ response.message }}
            </div>

            <div v-else-if="response.message && response.color == 'green'" class="alert alert-success" role="alert">
                {{ response.message }}
            </div>
        </div>
    </form>
</template>

<script>

import axios from 'axios';
import message from '../../plugins/messages';

export default {
    name: 'VerifyEmailBody',
    components: {
    },

    data() {
        return {
            token: '',
            email: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                verify_email: false,
            },
            messages: message,
        }
    },

    beforeRouteEnter(to, from, next) {
        // console.log('Token?: ');
        if (!to?.query?.token) {
            next({ name: 'login' });
        }

        next();
    },

    created() {
        this.token = this.$route?.query?.token || '';

        if (!this.token || this.token == '') {
            this.$router.push({ name: 'login' });
        }
        else {
            this.verifyEmail();
        }
    },

    methods: {
        verifyEmail() {

            const payload = {
                token: this.token,
            };
            // console.log(payload);

            axios.post('verify-email', payload)
                .then(response => {

                    // console.log(response.data);
                    this.email = response.data.data.email;

                    this.response.color = 'green';
                    this.response.message = message.VerifyEmailTokenInvalidSuccess;

                })
                .catch((e) => {
                    console.log(e);
                    const errorCode = e?.response?.data?.error || 'ServerError';
                    this.response.color = 'red';
                    this.response.message = message[errorCode];

                }).finally(() => {
                    this.spinner.verify_email = false;
                })

        },

    },

};
</script>