<template>
    <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center"
                style="border-radius: 53px">
                <div class="grid flex flex-column align-items-center">
                    <h1 class="text-900 font-bold text-5xl mb-2">Error {{ errorNumber }}</h1>
                    <span v-if="errorMsg" class="text-600 mb-5">{{ errorMsg }}</span>
                    <img src="/demo/images/error/asset-error.svg" alt="Error" class="mb-5" width="80%" />
                    <div v-if="linkTo" class="col-12 mt-5 text-center">
                        <i class="pi pi-fw pi-arrow-left text-blue-500 mr-2" style="vertical-align: center"
                            :href="linkTo">{{ linkTitle }}</i>
                    </div>
                </div>
            </div>
</template>

<script>

export default {
    name: 'ErrorFormBody',
    components: {
    },
    props: {
        errorNumber: {
            Number,
            required: false,
            default: () => []
        },
        errorMsg: {
            String,
            required: false,
            default: null
        },
        linkTitle: {
            String,
            required: false,
            default: () => []
        },
        linkTo: {
            String,
            required: false,
            default: null
        },
    },
};
</script>