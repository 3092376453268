<template>
    <form id="resetPassword" @submit.prevent.stop="resetPassword">
        <div v-if="response.message && response.color == 'red'" class="alert alert-danger" role="alert">
            {{ response.message }}
        </div>

        <div v-else-if="response.message && response.color == 'green'" class="alert alert-success" role="alert">
            {{ response.message }}
        </div>

        <div class="mb-3">
            <label for="email" class="block text-900 text-xl font-medium mb-2">Email</label>
            <InputGroup>
                <InputGroupAddon>
                    <i class="pi pi-user"></i>
                </InputGroupAddon>
                <InputText id="email" type="text" placeholder="Email address" class="w-full md:w-30rem md:h-3rem"
                    required style="padding: 1rem" v-model="email" />
            </InputGroup>
            <div class="error-msg form-floating">
                <span class="error-msg" v-if="!checkEmail()">{{ messages.EmailInvalid }}</span>
            </div>
        </div>

        <div class="mb-3">
            <label for="password" class="block text-900 text-xl font-medium mb-2">Password</label>
            <InputGroup>
                <InputGroupAddon>
                    <i class="pi pi-key"></i>
                </InputGroupAddon>
                <Password v-model="password" placeholder="Password" :toggleMask="true"
                    class="w-full md:w-30rem md:h-3rem" :feedback="true" required>
                    <template #header>
                        <div class="font-semibold text-xm mb-4">Pick a password</div>
                    </template>
                    <template #footer>
                        <Divider />
                        <ul class="pl-2 ml-2 my-0 leading-normal">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>At least one non numeric character</li>
                            <li>Minimum 8 characters</li>
                        </ul>
                    </template>
                </Password>
            </InputGroup>
        </div>


        <div class="mb-3">
            <label for="repassword" class="block text-900 text-xl font-medium mb-2">Retype</label>
            <InputGroup>
                <InputGroupAddon>
                    <i class="pi pi-key"></i>
                </InputGroupAddon>
                <Password v-model="repassword" placeholder="Retype Password" :toggleMask="true" @click="reCheckPassword"
                    class="w-full md:w-30rem md:h-3rem" :feedback="true" required>
                    <template #header>
                        <div class="font-semibold text-xm mb-4">Pick a password</div>
                    </template>
                    <template #footer>
                        <Divider />
                        <ul class="pl-2 ml-2 my-0 leading-normal">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>At least one non numeric character</li>
                            <li>Minimum 8 characters</li>
                        </ul>
                    </template>
                </Password>
            </InputGroup>
        </div>
        
        <Button type="submit" style="border-radius: 5px;" :label="spinner.message" class="w-full p-3 md:h-3rem mb-3"
            :disabled="spinner.resetPassword || !checkEmail() || !checkPassword()" id="btn-resetPassword">
            <img v-if="spinner.resetPassword" width="30" src="../../assets/img/spinner_color_white.svg" alt="" class="">
        </Button>

        
        <div>
            <div v-if="!reCheckPassword" class="align-middle alert alert-danger w-full" role="alert">
                As senhas digitadas não são iguais.
            </div>
            <div v-if="!checkPassword()" class="align-middle alert alert-danger w-full" role="alert">
                A senha não atende aos critérios mínimos de segurança.
            </div>
        </div>
    </form>
</template>

<script>

import axios from 'axios';
import message from '../../plugins/messages';
const util = require('../../plugins/util');

export default {
    name: 'ResetPwd',
    components: {},
    data() {
        return {
            email: '',
            token: '',
            password: '',
            repassword: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                resetPassword: false,
                message: message.ResetPasswordFalse,
            },
            showPassword: false,
            messages: message,
        }
    },

    created() {
        this.token = this.$route?.query?.token;

        if (!this.token || this.token == '') {
            this.$router.push({ name: 'login' });
        }
    },

    computed: {
        reCheckPassword() {
            // console.log(this.password === this.repassword);
            return this.password === this.repassword;
        }
    },

    methods: {
        async resetPassword() {

            const payload = {
                email: this.email,
                token: this.token,
                password: this.password,
            };

            // console.log(payload);
            this.resetResponse();

            axios.post('reset-password', payload)
                .then(response => {

                    this.clearForm()
                    console.log(response.data);

                    this.response.color = 'green';
                    this.response.message = message.ResetPassword_Success;
                    this.spinner.message = message.ResetPasswordFalse;

                })
                .catch((e) => {
                    console.log(e);
                    const errorCode = e?.response?.data?.error || 'ServerError';
                    this.response.color = 'red';
                    this.response.message = message[errorCode];

                    this.spinner.message = message.ResetPasswordFalse;

                }).finally(() => {
                    this.spinner.resetPassword = false;
                })

        },

        checkEmail() {
            return util.validaEmail(this.email);
        },

        checkPassword() {
            return ((util.validaPassword(this.password) && util.validaPassword(this.repassword)) && (this.password === this.repassword));
        },

        resetResponse() {
            this.response.color = '';
            this.response.message = '';

            this.spinner.resetPassword = true;
            this.spinner.message = message.ResetPasswordTrue;
        },

        toggleShow() {
            this.showPassword = !this.showPassword;
        },

        clearForm() {
            this.email = '';
            this.token = '';
            this.password = '';
        },

    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span {
    margin: 40px 0 0;
    text-align: left;
}

.error-msg {
    margin-top: 5px;
    text-align: left;
    color: red;
    font-weight: bold;
    font-size: 15px;
}

.error-msg-small {
    font-size: 11px;
}

span.error-msg {
    margin-left: 10px;
}
</style>