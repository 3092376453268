import router from './router';
import store from "@/store";


router.beforeEach(async (to, from, next) => {

  let permission = false;
  
  //Só realiza a validação caso o usuário não seja 'Super Admin' 
  if (!store.state.user.roles.includes('Super Admin')) {

    if (to.meta.roles && to.meta.roles.length > 0) {

      try {
        store.state.user.roles.forEach(element => {
          if (to.meta.roles.includes(element)) {
            permission = true
          }

          // if (permission) {
          //   console.log('Permitido');
          // }
          // else {
          //   console.log('Acesso não permitido!');
          //   next({ name: 'error' });
          // }
          if (!permission) {
            // console.log('Acesso não permitido!');
            next({ name: 'error' });
          }

        });

      } catch (error) { console.log('') }

    }
  }

  next();

})

router.afterEach(() => {

})
