<template>
  <FormBodyRegister></FormBodyRegister>
</template>

<script>

import FormBodyRegister from '@/components/auth/FormBodyRegister.vue';


export default {
  name: 'RegisterPage',
  components: {
    FormBodyRegister,
  },

  data() {
    return {
    }
  },
};
</script>