import Cookies from "js-cookie"

const TOKEN_NAME = '_userToken';
const USER_THEME = '_usertheme';
const USER_THEME_MODE = '_userthememode';

export default {
    setToken(token){
        Cookies.set('_userToken', token, "default_unit_second", {maxAge: 30});
    },

    getToken(){
        return Cookies.get(TOKEN_NAME);
    },

    deleteToken(){
        Cookies.remove(TOKEN_NAME);
    },
    
    setTheme(theme, mode){
        Cookies.set('_usertheme', theme, {expires: 10});
        Cookies.set('_userthememode', mode, {expires: 10});
    },

    getTheme(){
        return [Cookies.get(USER_THEME), Cookies.get(USER_THEME_MODE)];
    },

    deleteTheme(){
        Cookies.remove(USER_THEME);
        Cookies.remove(USER_THEME_MODE);
    },
}