<template>
  <FormAuth :formTitle="formTitle" :linkTo="linkTo" :linkTitle="linkTitle" :viewTo="viewTo"></FormAuth>
</template>

<script>
import FormAuth from '@/components/auth/FormAuth.vue';
export default {
  name: 'LoginPage',
  components: {
    FormAuth,
  },

  data() {
    return {
      formTitle: 'Sign in to continue',
      linkTo: '/forgot-password',
      linkTitle: 'Forgot password?',
      viewTo: 'LoginPage',
    }
  },
};
</script>