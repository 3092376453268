import axios from "axios";

export class ThemeService {
    getTheme() {
        return fetch('/demo/data/theme.json', { headers: { 'Cache-Control': 'no-cache' } })
            .then((res) => res.json())
            .then((d) => d.data);
    }

    async getMePreference() {
        return await axios.get('me/preference')
            .then()
            .catch((e) => {
                console.log(e);
            }).finally(() => { });
    }

    async updateMePreference(theme, theme_mode) {

        const payload = {
            theme: theme,
            theme_mode: theme_mode,
        };

        return await axios.put('me/preference', payload)
            .then()
            .catch((e) => {
                console.log(e);
            }).finally(() => { });
    }
}
